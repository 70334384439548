/* -------------------------------------------------------------------------------------------------
 * INTERNAL SERVICE ENVIRONMENT CONFIGS
 * -----------------------------------------------------------------------------------------------*/

import { resolveRequiredEnvVar } from '@littleotter/zz-kit/env';

export const DJANGO_BACKEND_SERVICE = {
  ROOT_URL: resolveRequiredEnvVar('DJANGO_SERVICE_ROOT_URL', process.env.DJANGO_SERVICE_ROOT_URL),
};

/**
 * URI for our GraphQL router which is responsible for routing GQL requests to the appropriate service (LO/LO1 etc)
 *
 * ? Why do we also have `LO_GQL_URI` defined below?
 * ! The `GRAPHQL_ROUTER_URI` does not support file uploads, so we need to use the LO GQL service directly for that
 */
export const GRAPHQL_ROUTER_URI = resolveRequiredEnvVar('GRAPHQL_ROUTER_URI', process.env.GRAPHQL_ROUTER_URI);

/**
 * Direct URI for the LO GQL service
 *
 * ? Why do we also have `GRAPHQL_ROUTER_URI` defined above?
 * ! The `GRAPHQL_ROUTER_URI` does not support file uploads, so we need to use the LO GQL service directly for that
 * ! All others GQL requests should go through `GRAPHQL_ROUTER_URI`
 */
export const LO_GQL_URI = resolveRequiredEnvVar('LO_GQL_URI', process.env.LO_GQL_URI);

/* -------------------------------------------------------------------------------------------------
 * 3RD PARTY SERVICE ENVIRONMENT CONFIGS
 * When the envvars are required, use the `resolveRequiredEnvVar` helper
 * -----------------------------------------------------------------------------------------------*/
export const LAUNCH_DARKLY = {
  CLIENT_ID: process.env.LAUNCH_DARKLY_CLIENT_ID || 'client-id-not-set',
};

const GTAG_CONTAINER_ID_DEFAULT = 'container-id-not-set';

export const GOOGLE_TAG_MANGER = {
  CONTAINER_ID_DEFAULT: GTAG_CONTAINER_ID_DEFAULT,
  CONTAINER_ID: process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID || GTAG_CONTAINER_ID_DEFAULT,
};

export * from './intakeq';
