import { useMutation, useQuery } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { math } from 'polished';
import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ActionButton,
  Button,
  Header,
  HTMLContainer,
  Icon,
  PdfViewer,
  Tag,
  TagsContainer,
  Tooltip,
} from '@littleotter/zz-legacy-components';

import { limitList } from '$shared/utils/lists';
import { getReadTime } from '$shared/utils/text';

import { type AddToFavorites, type AddToFavoritesVariables } from '../../../graphql/__generated__/AddToFavorites';
import {
  type LibraryResource,
  type LibraryResource_resource_tags_edges_node,
  type LibraryResource_resource_worryDomains_edges_node,
} from '../../../graphql/__generated__/LibraryResource';
import {
  type RemoveFromFavorites,
  type RemoveFromFavoritesVariables,
} from '../../../graphql/__generated__/RemoveFromFavorites';
import {
  ADD_TO_FAVORITES_MUTATION,
  LIBRARY_RESOURCE_QUERY,
  REMOVE_FROM_FAVORITES_MUTATION,
} from '../../../graphql/library-resource';
import { ResourceIllustration } from '../components/ResourceIllustration';
import { WorryDomainOrResourceTag } from '../components/WorryDomainOrResourceTag';
import { getTagList, getTagNames, getTooltipText, isTagListElementArray } from '../utils/tagList';

const isOniOS = Capacitor.getPlatform() === 'ios';

const HeroImage = styled.div`
  height: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 12`)};
  margin-top: ${({ theme }) =>
    isOniOS
      ? `calc(${math(`-4 * ${theme.deprecated_.sizeBasis}`)} - env(safe-area-inset-top))`
      : math(`-5 * ${theme.deprecated_.sizeBasis}`)};
  margin-right: ${(props) => math(`-1 * ${props.theme.deprecated_.sizeBasis}`)};
  margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  margin-left: ${(props) => math(`-1 * ${props.theme.deprecated_.sizeBasis}`)};
  background-color: ${(props) => props.theme.deprecated_.colors.gray50};
  overflow: hidden;
  position: relative;

  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    height: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 24`)};
  }
`;

const HeaderWrapper = styled.header`
  display: flex;
  font-size: ${(props) => props.theme.deprecated_.fontSizes.larger};
  margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

const ActionWrapper = styled.div`
  margin-left: auto;
  padding-top: ${(props) => math(`${props.theme.deprecated_.sizeBasis} / 4`)};

  & > button {
    padding: 0;
  }
`;

const StyledText = styled.div`
  color: ${(props) => props.theme.deprecated_.colors.gray};
  font-size: ${(props) => props.theme.deprecated_.fontSizes.smaller};
  margin-bottom: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 1.25`)};
`;

const StyledButton = styled(Button)`
  position: absolute;
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  top: ${({ theme }) => (isOniOS ? math(`${theme.deprecated_.sizeBasis} * 3`) : theme.deprecated_.sizeBasis)};
  left: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

const Resource = () => {
  const params = useParams<{ id: string }>();
  const pk = params.id;
  const history = useHistory();

  const { data } = useQuery<LibraryResource>(LIBRARY_RESOURCE_QUERY, {
    variables: { pk },
  });

  const [addToFavorites] = useMutation<AddToFavorites, AddToFavoritesVariables>(ADD_TO_FAVORITES_MUTATION);
  const [removeFromFavorites] = useMutation<RemoveFromFavorites, RemoveFromFavoritesVariables>(
    REMOVE_FROM_FAVORITES_MUTATION
  );

  const isSaved = useMemo(
    () => data?.viewer?.favorites.edges.map((edge) => edge?.node?.pk).includes(data.resource.pk) ?? false,
    [data]
  );

  if (!data) {
    return null;
  }

  const { resource } = data;
  const worryDomainNodes = resource.worryDomains.edges
    .map((worryDomainEdge) => worryDomainEdge?.node)
    .filter(
      (worryDomainNode): worryDomainNode is LibraryResource_resource_worryDomains_edges_node => !!worryDomainNode
    );

  const tagNodes = resource.tags.edges
    .map((tagEdge) => tagEdge?.node)
    .filter((tagNode): tagNode is LibraryResource_resource_tags_edges_node => !!tagNode);

  const tagList = getTagList(worryDomainNodes, tagNodes);
  const tagNames = getTagNames(tagList);
  const numberOfWorryDomainsToShow = 3;

  const saveFn = isSaved ? removeFromFavorites : addToFavorites;

  return (
    <>
      <Helmet>
        <title>{`Little Otter | ${resource.title}`}</title>
      </Helmet>
      <HeroImage>
        <StyledButton variant="clearStyles" onClick={() => history.goBack()}>
          <Icon name="BackArrow" />
        </StyledButton>
        <ResourceIllustration pk={resource.pk} tags={tagNames} />
      </HeroImage>
      <HeaderWrapper>
        <Header as="h3">{resource.title}</Header>
        <ActionWrapper>
          <ActionButton
            icon={isSaved ? <BsBookmarkFill /> : <BsBookmark />}
            onClick={() => saveFn({ variables: { resourceId: resource.pk } })}
          />
        </ActionWrapper>
      </HeaderWrapper>
      <StyledText>{getReadTime(resource.content)} min read</StyledText>
      <TagsContainer>
        {limitList(tagList, numberOfWorryDomainsToShow).map((listElement) =>
          isTagListElementArray(listElement) ? (
            <Fragment key={`+${listElement.length}`}>
              <Tag data-tip={getTooltipText(listElement)}>{`+${listElement.length}`}</Tag>
              <Tooltip effect="solid" multiline />
            </Fragment>
          ) : (
            <WorryDomainOrResourceTag
              key={`${listElement.__typename}:${listElement.id}`}
              worryDomainOrResource={listElement}
              isLink
            />
          )
        )}
      </TagsContainer>
      {resource.contentFile !== '' ? (
        <PdfViewer fileUrl={resource.contentFile} />
      ) : (
        <HTMLContainer content={resource.content} />
      )}
    </>
  );
};

export default Resource;
