import { useQuery } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/zz-legacy-components';

import {
  type LibraryWorryDomains,
  type LibraryWorryDomains_worryDomains_edges_node,
} from '../../../../graphql/__generated__/LibraryWorryDomains';
import { type ResourceTags, type ResourceTags_tags_edges_node } from '../../../../graphql/__generated__/ResourceTags';
import { GET_ALL_WORRY_DOMAINS } from '../../../../graphql/library-explore';
import { RESOURCE_TAGS_QUERY } from '../../../../graphql/resource-tags';
import { ageTags } from './data';
import { AgeFilter, TagFilter, WorryDomainFilter, type AgeFilterProps, type TagFilterProps } from './sections';
import { Button, Container, Loading } from './styled';
import { getToggleSelectedTagCallback, orderAgeRanges, orderTagsByPk, type FilterItem } from './utils';

export type FiltersList = {
  ageRanges: FilterItem[];
  worryDomain: FilterItem | undefined;
  tags: FilterItem[];
};

type FilterProps = {
  isVisible: boolean;
  currentFilters?: FiltersList;
  onChange?: (filters: FiltersList) => void;
};

export const Filters: FC<PropsWithChildren<FilterProps>> = ({ isVisible, currentFilters, onChange }) => {
  const [selectedAgeRanges, setSelectedAgeRanges] = useState(currentFilters?.ageRanges ?? []);
  const [selectedWorryDomain, setSelectedWorryDomain] = useState(currentFilters?.worryDomain);
  const [selectedTags, setSelectedTags] = useState(currentFilters?.tags ?? []);

  const { data: worryDomainsData, loading: worryDomainsLoading } = useQuery<LibraryWorryDomains>(GET_ALL_WORRY_DOMAINS);
  const { data: tagsData, loading: tagsLoading } = useQuery<ResourceTags>(RESOURCE_TAGS_QUERY);

  useEffect(() => {
    setSelectedAgeRanges(currentFilters?.ageRanges ?? []);
    setSelectedWorryDomain(currentFilters?.worryDomain);
    setSelectedTags(currentFilters?.tags ?? []);
  }, [currentFilters, isVisible]);

  const isLoading = worryDomainsLoading || tagsLoading;

  const tags = tagsData?.tags?.edges
    .filter((edge) => !ageTags.some((ageTag) => edge?.node?.pk === ageTag.pk))
    .map((edge) => edge?.node as ResourceTags_tags_edges_node);

  const worryDomains = worryDomainsData?.worryDomains?.edges.map(
    (edge) => edge?.node as LibraryWorryDomains_worryDomains_edges_node
  );

  const toggleSelectedAgeRange: AgeFilterProps['onSelect'] = useCallback(
    (item) => getToggleSelectedTagCallback(item, setSelectedAgeRanges),
    []
  );

  const toggleSelectedTags: TagFilterProps['onSelect'] = useCallback(
    (item) => getToggleSelectedTagCallback(item, setSelectedTags),
    []
  );

  const onApplyFilters = () => {
    onChange?.({
      ageRanges: orderAgeRanges(selectedAgeRanges),
      worryDomain: selectedWorryDomain,
      tags: orderTagsByPk(selectedTags),
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <Container>
          <Header as="h5">Filters</Header>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <AgeFilter data={ageTags} selected={selectedAgeRanges} onSelect={toggleSelectedAgeRange} />
              <WorryDomainFilter data={worryDomains} selected={selectedWorryDomain} onSelect={setSelectedWorryDomain} />
              <TagFilter data={tags} selected={selectedTags} onSelect={toggleSelectedTags} />
              <Button onClick={onApplyFilters}>Apply Filters</Button>
            </>
          )}
        </Container>
      )}
    </AnimatePresence>
  );
};
