/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum CancellationTypes {
  cancel = "cancel",
  reschedule = "reschedule",
}

/**
 * An enumeration.
 */
export enum CaregiverToChildRelationshipEnum {
  FAMILY_FRIEND = "FAMILY_FRIEND",
  GRANDPARENT = "GRANDPARENT",
  GUARDIAN = "GUARDIAN",
  OTHER = "OTHER",
  PARENT = "PARENT",
  RELATIVE = "RELATIVE",
}

/**
 * re (PD-3157): Don't bother fixing enum casing.
 */
export enum ChallengeRisks {
  higher = "higher",
  typical = "typical",
}

export enum ConversationStateArgument {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  INACTIVE = "INACTIVE",
}

/**
 * An enumeration.
 */
export enum ConversationStateEnum {
  active = "active",
  closed = "closed",
  inactive = "inactive",
}

/**
 * An enumeration.
 */
export enum DeviceTypes {
  android = "android",
  ios = "ios",
  web = "web",
}

/**
 * An enumeration.
 */
export enum InsuranceTypeOfServiceEnum {
  PSYCHIATRY = "PSYCHIATRY",
  THERAPY = "THERAPY",
}

/**
 * All status except "Completed" correspond to the native IntakeQ status
 *     https: // support.intakeq.com/article/204-intakeq-appointments-api#appointment-object
 * 
 *     Confirmed – The appointment has been confirmed (either manually or automatically).
 *     WaitingConfirmation – The appointment has not been confirmed by the staff.
 *     Declined – The appointment has been declined by the staff.
 *     Canceled – The appointment has been canceled.
 *     Missed – The appointment has been marked as missed by the staff.
 *     Completed – The appointment has been taken and has a blocked treatment note.
 *     
 */
export enum IntakeQAppointmentStatusEnum {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  MISSED = "MISSED",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
}

export enum PatientRelationshipType {
  CADAVER_DONOR = "CADAVER_DONOR",
  CHILD = "CHILD",
  DEPENDENT_OF_MINOR_DEPENDENT = "DEPENDENT_OF_MINOR_DEPENDENT",
  EMANCIPATED_MINOR = "EMANCIPATED_MINOR",
  EMPLOYEE = "EMPLOYEE",
  FATHER = "FATHER",
  FOSTER_CHILD = "FOSTER_CHILD",
  GRANDCHILD = "GRANDCHILD",
  GRANDPARENT = "GRANDPARENT",
  HANDICAPPED_DEPENDENT = "HANDICAPPED_DEPENDENT",
  INJURED_PLAINTIFF = "INJURED_PLAINTIFF",
  LIFE_PARTNER = "LIFE_PARTNER",
  MOTHER = "MOTHER",
  NATURAL_CHILD = "NATURAL_CHILD",
  NIECE_OR_NEPHEW = "NIECE_OR_NEPHEW",
  ORGAN_DONOR = "ORGAN_DONOR",
  OTHER = "OTHER",
  SELF = "SELF",
  SIGNIFICANT_OTHER = "SIGNIFICANT_OTHER",
  SPONSORED_DEPENDENT = "SPONSORED_DEPENDENT",
  SPOUSE = "SPOUSE",
  STEPCHILD = "STEPCHILD",
  UNKNOWN = "UNKNOWN",
  WARD_OF_THE_COURT = "WARD_OF_THE_COURT",
}

/**
 * An enumeration.
 */
export enum ProviderTitleEnum {
  CARE_LEAD = "CARE_LEAD",
  PARENT_COACH = "PARENT_COACH",
  PARENT_SPECIALIST = "PARENT_SPECIALIST",
  PSYCHIATRIST = "PSYCHIATRIST",
  THERAPIST = "THERAPIST",
}

/**
 * An enumeration.
 */
export enum RequiredFamilyDataEnum {
  INVITATION_CODE = "INVITATION_CODE",
  SUBSCRIBER = "SUBSCRIBER",
}

/**
 * re (PD-3157): Don't bother fixing enum casing.
 */
export enum RisksWithMissing {
  high = "high",
  low = "low",
  medium = "medium",
  missing = "missing",
  very_high = "very_high",
}

/**
 * re (PD-3157): Don't bother fixing enum casing.
 */
export enum SubScaleRisks {
  high = "high",
  low = "low",
  medium = "medium",
}

/**
 * An enumeration.
 */
export enum UserState {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export interface AddDeviceToUserMutationInput {
  registrationToken: string;
  deviceType: DeviceTypes;
  clientMutationId?: string | null;
}

export interface AddUserChildMutationInput {
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  gender?: string | null;
  preferredName?: string | null;
  pronouns?: string | null;
  relationship?: string | null;
  caregiverRelationship?: CaregiverToChildRelationshipEnum | null;
  isSeekingCare?: boolean | null;
  insuranceAuthorization?: InsuranceAuthorizationInput | null;
  insuranceSubscriber?: ChildSubscriberRelationshipInput | null;
  allowUpdates?: boolean | null;
  clientMutationId?: string | null;
}

export interface AddressInput {
  streetAddress: string;
  state: string;
  city: string;
  zipCode: string;
}

export interface CancelAppointmentMutationInput {
  appointmentId: string;
  cancellationType: CancellationTypes;
  clientMutationId?: string | null;
}

export interface ChildSubscriberRelationshipInput {
  insuranceSubscriberId: number;
  relationshipToSubscriber: PatientRelationshipType;
}

export interface HideChatMessageInput {
  conversationSid: string;
  messageSid: string;
  clientMutationId?: string | null;
}

export interface InsuranceAuthorizationInput {
  insurancePartnerId: number;
  referralNumber: string;
}

export interface InsuranceReferralInput {
  childId: string;
  insurancePartnerId: number;
  referralNumber: string;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
}

export interface QuestionResponseInputType {
  questionPk: number;
  answerPk: number;
}

export interface RestoreChatMessageInput {
  conversationSid: string;
  messageSid: string;
  clientMutationId?: string | null;
}

export interface SendTemperTantrumResultsEmailMutationInput {
  email: string;
  childName: string;
  tantrumScore: string;
  tantrumSummary: string;
  clientMutationId?: string | null;
}

export interface SubmitSurveyResponsesMutationInput {
  email?: string | null;
  surveys: SurveyResponseInputType[];
  childId?: number | null;
  clientMutationId?: string | null;
}

export interface SurveyResponseInputType {
  surveyId: number;
  questionResponses: QuestionResponseInputType[];
}

export interface UpdateChildProfileMutationInput {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
  gender?: string | null;
  profilePicture?: any | null;
  preferredName?: string | null;
  pronouns?: string | null;
  relationship?: string | null;
  clientMutationId?: string | null;
}

export interface UpdatePasswordMutationInput {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
  clientMutationId?: string | null;
}

export interface UpdateProfileMutationInput {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  profilePicture?: any | null;
  address?: AddressInput | null;
  phoneNumber?: string | null;
  allowMarketingSms?: boolean | null;
  allowAccountSms?: boolean | null;
  clientMutationId?: string | null;
}

export interface UserTermConditionsMutationInput {
  agreedTermsAndPolicies: number[];
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
