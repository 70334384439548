import { type FC, type PropsWithChildren } from 'react';

import { Header, List } from '@littleotter/zz-legacy-components';

import { Modal } from './styled';

export type ExpertDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ExpertDetailsModal: FC<PropsWithChildren<ExpertDetailsModalProps>> = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    raised
    centered
    onCloseButtonClick={onClose}
    onBackgroundClick={onClose}
    onEscapeKeydown={onClose}
  >
    <Header as="h3">Who You’ll Meet</Header>
    <p>
      We take the information you shared with us about your family and pair you with a kind, experienced child mental
      health expert to start you on your journey the right way.
    </p>
    <p>Each person on our clinical team:</p>
    <List>
      <li>Has been specifically brought on because of their years of experience working with children</li>
      <li>Understands the importance of connection</li>
      <li>Was selected by our founder, Dr. Helen Egger, the leading expert in child mental health</li>
    </List>
  </Modal>
);
