import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from '@littleotter/zz-kit/react';

import {
  type ProviderListQuery,
  type ProviderListQuery_insurancePartners,
} from '../../../../graphql/__generated__/ProviderListQuery';
import { PROVIDER_LIST_QUERY } from '../../../../graphql/referral';
import { useGraphQLErrorHandling } from '../../../hooks/useGraphQLErrorHandling';

export type SetChildSelectedProviderType = ({
  childId,
  providerId,
}: {
  childId?: string;
  providerId?: string | number;
}) => void;

type UseReferralProvider = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  providerList: ProviderListQuery_insurancePartners[];
  childSelectedProvider?: ProviderListQuery_insurancePartners;
  setChildSelectedProvider: SetChildSelectedProviderType;
};

export const useReferralProvider: UseReferralProvider = ({ isLoggedIn }) => {
  const [{ providerId: queryProviderId }] = useSearchParams<{ providerId: string | undefined }>();
  const [childSelectedProvider, setChildProvider] = useState<ProviderListQuery_insurancePartners | undefined>(
    undefined
  );
  const {
    data,
    loading: providerListLoading,
    error,
  } = useQuery<ProviderListQuery>(PROVIDER_LIST_QUERY, {
    skip: !isLoggedIn,
  });

  useGraphQLErrorHandling(error);

  const providerList = useMemo(() => data?.insurancePartners || [], [data]);

  const getProviderFromId = useCallback(
    (providerId?: number | string) => providerList.find((p) => p.id === Number(providerId)),
    [providerList]
  );

  const setChildSelectedProvider: SetChildSelectedProviderType = useCallback(
    ({ providerId }) => {
      const selectedProvider = getProviderFromId(providerId);
      setChildProvider(selectedProvider);
    },
    [getProviderFromId]
  );

  useEffect(() => {
    setChildSelectedProvider({ providerId: queryProviderId });
  }, [queryProviderId, setChildSelectedProvider]);

  return {
    providerListLoading,
    providerList,
    childSelectedProvider,
    setChildSelectedProvider,
  };
};
