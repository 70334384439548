import { math, rgba } from 'polished';
import styled from 'styled-components';

import { Header } from '@littleotter/zz-legacy-components';

export const DomainHeader = styled(Header).attrs({ as: 'h5' })`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  letter-spacing: normal;

  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 3`)};
`;

export const StyledP = styled.p`
  color: ${({ theme }) => rgba(theme.deprecated_.colors.text, 0.8)};
`;
