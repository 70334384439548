import styled from 'styled-components';

import { Button, Icon } from '@littleotter/zz-legacy-components';

export const ButtonContainer = styled.div`
  text-align: center;
`;

export const StyledButton = styled(Button).attrs({ variant: 'clearStyles' })`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  margin-bottom: 1rem;

  & > * {
    vertical-align: middle;
  }
`;

export const ButtonIcon = styled(Icon).attrs({ name: 'MessagesFill' })`
  & > path {
    fill: ${({ theme }) => theme.deprecated_.colors.gray};
  }
`;
