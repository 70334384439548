import { type FC, type PropsWithChildren } from 'react';

import { Header, HTMLContainer, Link } from '@littleotter/zz-legacy-components';

import { type HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_familyDynamics } from '../../../../graphql/__generated__/HolidayReportQuery';
import { CollapseWithIcon } from '../../components';
import { Section } from './styled';

type FamilyDynamicsProps = {
  familyDynamics: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_familyDynamics;
};

const FamilyDynamicsCollapseSection: FC<PropsWithChildren<{ meaning: string; whatICanDoToSupport: string }>> = ({
  meaning,
  whatICanDoToSupport,
}) => (
  <CollapseWithIcon iconName="Sun" label={meaning}>
    <HTMLContainer content={whatICanDoToSupport} />
  </CollapseWithIcon>
);

export const FamilyDynamics: FC<PropsWithChildren<FamilyDynamicsProps>> = ({ familyDynamics }) => {
  return (
    <Section>
      <Header as="h4" id="family-dynamics">
        Family Dynamics
      </Header>
      <p>You indicated that you wanted some support for the following. Click on the sections below for helpful tips.</p>
      {(Object.keys(familyDynamics) as Array<keyof typeof familyDynamics>).map((dynamicKey) => {
        if (dynamicKey === '__typename') return null;

        const familyDynamic = familyDynamics[dynamicKey];
        if (!familyDynamic) return null;

        return (
          <FamilyDynamicsCollapseSection
            key={familyDynamic.meaning}
            meaning={familyDynamic.meaning}
            whatICanDoToSupport={familyDynamic.whatICanDoToSupport}
          />
        );
      })}
      <CollapseWithIcon iconName="Heart" label="Activities to do with your child" defaultOpen>
        <Link
          href="https://littleotter-assets.s3.us-west-2.amazonaws.com/assessments/holiday/Grateful+Acorn_FamilyDynamics.pdf"
          isAnchor
          target="_blank"
        >
          Grateful Acorns
        </Link>
        <br />
        <Link
          href="https://littleotter-assets.s3.us-west-2.amazonaws.com/assessments/holiday/mood+jars_family+dynamics.pdf"
          isAnchor
          target="_blank"
        >
          Mood Jars
        </Link>
      </CollapseWithIcon>
    </Section>
  );
};
