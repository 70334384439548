import { motion } from 'framer-motion';
import { math } from 'polished';
import { type FC } from 'react';
import styled from 'styled-components';

import {
  Button as BaseButton,
  Loading as BaseLoading,
  Select as BaseSelect,
  type SelectProps,
} from '@littleotter/zz-legacy-components';

const enum variant {
  visible = 'visible',
  hidden = 'hidden',
}

const motionVariants = {
  [variant.visible]: {
    opacity: 1,
    y: 0,
  },
  [variant.hidden]: {
    opacity: 0,
    transitionEnd: {
      y: '-4rem',
    },
  },
};

export const Container = styled(motion.section).attrs({
  initial: variant.hidden,
  animate: variant.visible,
  exit: variant.hidden,
  variants: motionVariants,
})`
  position: absolute;
  right: 0;
  min-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 24`)};
  margin-top: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis} / 2`)};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  background: ${({ theme }) => theme.deprecated_.colors.white};
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
  z-index: 999;

  h5 {
    font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.3675`)};
    font-weight: ${({ theme }) => theme.deprecated_.fontWeights.regular};
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

export const Loading = styled(BaseLoading)`
  display: block;
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4`)} auto;
`;

export const Section = styled.section`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const TagsContainer = styled.div`
  margin: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis} * 0.35`)};
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const Select = styled<FC<SelectProps<number>>>(BaseSelect)`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  line-height: 1.2;
`;
