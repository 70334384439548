import { type FC, type PropsWithChildren } from 'react';

import { FullButton, Header, Link, Modal } from '@littleotter/zz-legacy-components';

import { supportProxyUrl } from '$shared/utils/proxy';

import { ModalContent, StyledActionsContainer, StyledDescription, StyledImage, StyledLink } from './styled';

type WrongAuthorizationNumberModalProps = {
  isOpen: boolean;
  onSkipClick: () => void;
  closeHandler: () => void;
  codeLabel?: string;
};

export const WrongAuthorizationNumberModal: FC<PropsWithChildren<WrongAuthorizationNumberModalProps>> = ({
  isOpen,
  onSkipClick,
  closeHandler,
  codeLabel,
}) => {
  return (
    <Modal isOpen={isOpen} fullWidth onEscapeKeydown={closeHandler} onCloseButtonClick={closeHandler}>
      <ModalContent>
        <StyledImage src="./assets/WorriesAnxiety.svg" alt="Image of otters family" />
        <Header as="h4">Sorry, your authorization information didn’t work.</Header>
        <StyledDescription>
          It can take a few days for us to receive new authorization information. If you were recently referred and
          believe you entered the correct information, please try again in 1-2 days. You can add your authorization
          information to your account in the “account” section of the app.
          {'\n'} {'\n'}
          If you received your {codeLabel} over two weeks ago, try entering your code again or{' '}
          <Link href={supportProxyUrl}>contact support </Link>
          for assistance.
        </StyledDescription>
        <StyledActionsContainer>
          <FullButton variant="secondary" onClick={onSkipClick}>
            Skip authorization and continue
          </FullButton>
          <StyledLink onClick={closeHandler}>Edit authorization information</StyledLink>
        </StyledActionsContainer>
      </ModalContent>
    </Modal>
  );
};
