import { type ComponentType, type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Icon, ProfilePicture } from '@littleotter/zz-legacy-components';

import { CardCenterSection, CardLabel, CardLeftSection, CardRightSection, StyledCard, StyledLinkCard } from '../styled';

const StyledCardRightSection = styled(CardRightSection)`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  vertical-align: middle;
`;

export type FamilyMemberCardProps = {
  profilePictureSrc?: string;
  text: string;
  subText: string;
};

export type FamilyMemberLinkCardProps = FamilyMemberCardProps & {
  isAnchor?: boolean;
  onClick?: () => void;
  href?: string;
  Placeholder?: ComponentType<PropsWithChildren>;
};

export const FamilyMemberLinkCard: FC<PropsWithChildren<FamilyMemberLinkCardProps>> = ({
  isAnchor = false,
  onClick,
  href,
  profilePictureSrc,
  text,
  Placeholder,
}) => (
  <StyledLinkCard isAnchor={isAnchor} onClick={onClick} href={href}>
    <CardLeftSection>
      <ProfilePicture size="smallest" src={profilePictureSrc} Placeholder={Placeholder} />
    </CardLeftSection>
    <CardCenterSection>
      <CardLabel>{text}</CardLabel>
    </CardCenterSection>
    <StyledCardRightSection>
      <Icon name="ChevronRight" />
    </StyledCardRightSection>
  </StyledLinkCard>
);

export const FamilyMemberCard: FC<PropsWithChildren<FamilyMemberCardProps>> = ({ profilePictureSrc, text }) => (
  <StyledCard>
    <CardLeftSection>
      <ProfilePicture size="smallest" src={profilePictureSrc} />
    </CardLeftSection>
    <CardCenterSection>
      <CardLabel>{text}</CardLabel>
    </CardCenterSection>
  </StyledCard>
);
