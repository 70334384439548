import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Icon } from '@littleotter/zz-legacy-components';

const IconWrapper = styled.div<ReadStatusProps>`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.large};
  color: ${({ read, theme }) => (read ? theme.deprecated_.colors.textBlue : theme.deprecated_.colors.text)};
`;

type ReadStatusProps = {
  read: boolean;
  className?: string;
};

export const ReadStatus: FC<PropsWithChildren<ReadStatusProps>> = ({ read, className }) => (
  <IconWrapper className={className} read={read}>
    {read ? <Icon name="MessageReceived" /> : <Icon name="MessageSent" />}
  </IconWrapper>
);
