import { type FC } from 'react';

import { Link, Navbar } from '@littleotter/zz-legacy-components';

import { useViewer } from '../../shared/contexts/Viewer';
import {
  StyledActionsWrapper,
  StyledContainer,
  StyledImage,
  StyledImageContainer,
  StyledList,
  StyledPrimaryText,
  StyledSecondaryText,
  StyledSubtitle,
  StyledTextWrapper,
} from './styled';

export const MatchListLanding: FC = () => {
  const { viewer } = useViewer();
  return (
    <div>
      <Navbar bigHeight dark showLogo />
      <StyledContainer>
        <div>
          <StyledTextWrapper>
            <StyledPrimaryText>
              Hi {viewer?.firstName}, we’re excited to kickoff your care with Little Otter and get you scheduled for
              your first session!
            </StyledPrimaryText>
            <StyledSubtitle>What to Expect:</StyledSubtitle>
            <StyledSecondaryText>
              We start with an <b>Initial Assessment</b> followed by a <b>Feedback Session</b>.
            </StyledSecondaryText>
            <StyledList>
              <li>
                <b>Initial Assessment (90 minutes)</b> <br />
                <i>Child & parent(s) should be present for this session</i>. Your provider will complete a full
                assessment with your family during this time which will inform your Little Otter care journey.
              </li>
              <li>
                <b>Feedback Session (45 minutes)</b> <br />
                <i>Only the parent(s) should be present for this session</i>. Parent(s) will meet with the provider to
                discuss the outcome of the assessment session and the appropriate care path going forward.
              </li>
            </StyledList>
            <StyledSubtitle>What's Next:</StyledSubtitle>
            <StyledSecondaryText>
              After both sessions are completed, a member of Little Otter will reach out to schedule you for your
              recurring weekly sessions. Please note that these may be with a different provider than the one you meet
              during your initial assessment & feedback sessions.
            </StyledSecondaryText>
          </StyledTextWrapper>
          <StyledActionsWrapper>
            <Link
              href={`https://docs.google.com/forms/d/e/1FAIpQLSdbSvegrl-6lRYo341i39_knuazHuXtP9IQsXH6gSDCfG0Odg/viewform?usp=pp_url&entry.507952466=${viewer?.email}`}
              isAnchor
              target="_blank"
              size="large"
              variant="textBlue"
            >
              Continue
            </Link>
          </StyledActionsWrapper>
        </div>
        <StyledImageContainer>
          <StyledImage alt="Image a mother hugging her child" />
        </StyledImageContainer>
      </StyledContainer>
    </div>
  );
};
