import { math } from 'polished';
import styled from 'styled-components';

import { ProfilePicture } from '@littleotter/zz-legacy-components';

import arrowRight from '../../../assets/arrowRight.svg';
import { Card } from '../../Card';
import KoalaFamily from './KoalaFamily.svg';

export const StyledContainer = styled(Card)`
  min-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.6`)};
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  user-select: none;
`;

export const StyledContent = styled.div`
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 0.875`)};
  color: ${({ theme }) => `${theme.deprecated_.colors.text}B2`};
  padding-right: ${({ theme }) => theme.deprecated_.fontSizes.regular};
`;

export const StyledHeader = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const StyledTitleContainer = styled.div`
  align-self: center;
  margin-left: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const StyledTitle = styled.h1`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: inherit;
  color: ${({ theme }) => theme.deprecated_.colors.text};

  /* Just show 4 lines cross-browser */
  max-height: ${math(`1.38rem * 3`)};
  overflow: hidden;
  white-space: pre-line;

  /* Adding text ellipsis but lower compability */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const StyledSubtitle = styled.h4`
  font-size: inherit;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const StyledBody = styled.p`
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  width: 100%;

  /* Just show 4 lines cross-browser */
  height: ${math(`1.38rem * 4`)};
  overflow: hidden;
  white-space: pre-line;

  /* Adding text ellipsis but lower compability */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const StyledNextArrow = styled.img.attrs({
  alt: 'Next arrow',
  src: arrowRight,
})`
  align-self: center;
  min-width: 0.55;
  flex-shrink: 0;
  color: ${({ theme }) => theme.deprecated_.colors.text}4D;
  margin: 0 ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.375`)};
`;

const StyledPlaceholder = styled.img.attrs({
  src: KoalaFamily,
})`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const StyledProfilePicture = styled(ProfilePicture).attrs({
  Placeholder: StyledPlaceholder,
})`
  border-radius: 50%;
  flex-shrink: 0;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.575`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.575`)};
  border: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.3`)} solid
    ${({ theme }) => theme.deprecated_.colors.lightestGray};
  margin-right: 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
