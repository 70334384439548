import { type FC, type PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form6';
import { useHistory, useLocation } from 'react-router-dom';

import { Header, Select } from '@littleotter/zz-legacy-components';

import { logger } from '$services/logging';
import { useChildReferral } from '$shared/contexts/Child';
import { useBannerMessage } from '$shared/hooks';
import { getLocationDescriptor, getRedirectValue } from '$shared/utils/rerouter/rerouter';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { RequiredFamilyDataEnum } from '../../../../graphql/__generated__/globalTypes';
import { routes } from '../../../../routes';
import { BottomActionButtons } from '../../components/BottomActionButtons';
import { Description, MarginChildren, StyledForm } from './styled';

type ReferralChildProviderFormData = {
  providerId: number | undefined;
};
interface LocationState {
  from: string;
}

const referralChildProviderDefaultValues: ReferralChildProviderFormData = {
  providerId: undefined,
};

const DEFAULT_OPTION = { label: 'No referral', value: 0 };

export const ReferralProviderSelector: FC<PropsWithChildren> = () => {
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const { control, handleSubmit, errors, formState } = useForm<ReferralChildProviderFormData>({
    defaultValues: referralChildProviderDefaultValues,
    mode: 'all',
  });
  const { childInfo, providerList, setChildSelectedProvider } = useChildReferral();
  const { setBannerMessage } = useBannerMessage();
  if (!childInfo || !providerList || !setChildSelectedProvider) {
    return <PageWideLoading />;
  }
  const redirectValue = getRedirectValue();

  // If the redirect value is any of the profile pages then we return them there instead of the child summary page
  const shouldSkipSummary = redirectValue && redirectValue.includes(routes.profile.base.path);
  const continueWithoutReferralUrl = shouldSkipSummary
    ? redirectValue
    : getLocationDescriptor({
        route: routes.child.summary,
        forward: true,
        referrerLocation: state?.from,
      });

  const continueWithoutReferralAction = () => {
    history.push(continueWithoutReferralUrl);
  };

  const providerOptionsFormat = providerList
    .filter((p) => p.requiredFamilyData !== RequiredFamilyDataEnum.SUBSCRIBER)
    .map((provider) => ({
      label: provider.name,
      value: provider.id,
    }));

  const providerOptions = [DEFAULT_OPTION, ...providerOptionsFormat];

  const goNextPage = (providerId?: number) => {
    const routeWithDynamicParam = providerId
      ? getLocationDescriptor({
          route: routes.referral.details,
          forward: true,
          extraParams: [`providerId=${providerId}&childId=${childInfo.id}`],
          referrerLocation: state?.from,
        })
      : continueWithoutReferralUrl;
    history.push(routeWithDynamicParam);
  };

  const onSubmit = async (formData: ReferralChildProviderFormData) => {
    try {
      const { providerId } = formData;
      setChildSelectedProvider({ childId: childInfo.id, providerId });
      goNextPage(providerId);
    } catch (e) {
      logger.error(new Error('Error submitting ReferralChildProviderFormData', { cause: e }));
      setBannerMessage({ type: 'error', message: 'Something went wrong. Try again later.' });
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <MarginChildren>
        <Header as="h3">Was {childInfo.firstName} referred by an insurance provider or other organization?</Header>
        <Description>
          Only choose this option if you’ve received a referral from one of the organizations listed.
        </Description>
        <Controller
          as={Select}
          control={control}
          name="providerId"
          label="Select partner"
          noEmptyOption
          placeholder="No referral"
          options={providerOptions}
          defaultValue={DEFAULT_OPTION.value}
          errors={errors}
        />
      </MarginChildren>
      <BottomActionButtons
        showLink
        submitText="Continue"
        submitProps={{
          type: 'submit',
          variant: 'secondary',
          disabled: !formState.isValid,
        }}
        linkText="Continue without referral"
        linkAction={continueWithoutReferralAction}
      />
    </StyledForm>
  );
};
