import { type FC, type PropsWithChildren } from 'react';

import { Tag } from '@littleotter/zz-legacy-components';

import { CollapseItemLabelContainer } from './styled';

type CollapseItemLabelProps = {
  label: string;
  selectedWorryDomainPks: number[];
};

export const CollapseItemLabel: FC<PropsWithChildren<CollapseItemLabelProps>> = ({ label, selectedWorryDomainPks }) => (
  <CollapseItemLabelContainer>
    <span>{label}</span>
    <Tag
      variant="compact"
      backgroundColor={selectedWorryDomainPks.length ? 'lightPurple' : 'lighterRed'}
      textColor={selectedWorryDomainPks.length ? 'darkerPurple' : 'lightRed'}
    >
      {selectedWorryDomainPks.length}
    </Tag>
  </CollapseItemLabelContainer>
);
