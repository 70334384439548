import { math } from 'polished';
import styled from 'styled-components';

import { ProgressBar } from '@littleotter/zz-legacy-components';

export const StyledContainer = styled.div`
  padding: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => sizeBasis};
  background-color: ${({ theme }) => theme.deprecated_.colors.cream};
`;

export const StyledContent = styled.div<{ roundTopBorders: boolean }>`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
    roundTopBorders,
  }) => {
    const borderValues = math(`0.625 * ${sizeBasis}`);
    if (roundTopBorders) return borderValues;
    return `0 0 ${borderValues} ${borderValues}`;
  }};
  padding: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`1.25 * ${sizeBasis}`)};
  min-height: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => `calc(100vh - calc(env(safe-area-inset-bottom) + ${math(`9.25 * ${sizeBasis}`)}))`};
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  > div {
    flex-grow: 1;
  }
`;

export const StyledQuestionStatus = styled.div`
  ${({ theme }) => `
    font-size: ${math(`0.75 * ${theme.deprecated_.sizeBasis}`)};

    span {
      font-weight: ${theme.deprecated_.fontWeights.semiBold};
    }
  `}
`;

export const StyledProgressContainer = styled.div`
  padding: ${({ theme }) => {
    const basicPadding = theme.deprecated_.sizeBasis;
    const padding1 = math(`${basicPadding} * 1.5`);
    return `${basicPadding} ${padding1} ${basicPadding} ${padding1}`;
  }};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`0.313 * ${sizeBasis}`)};
`;

export const StyledProgressBarContainer = styled.div`
  display: flex;
  gap: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 0.544`)};
`;

export const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`0.5 * ${sizeBasis}`)};
  --progress-bar-color: ${({ theme }) => theme.deprecated_.colors.brandPurple};
`;

export const StyledCardTitle = styled.div`
  ${({ theme }) => `
    background-color: ${theme.deprecated_.colors.brandPurple};
    border-radius: ${math(`0.625 * ${theme.deprecated_.sizeBasis}`)} ${math(
      `0.625 * ${theme.deprecated_.sizeBasis}`
    )} 0 0;
    height: ${math(`1.75 * ${theme.deprecated_.sizeBasis}`)};
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-size: ${math(`0.75 * ${theme.deprecated_.sizeBasis}`)};
      font-weight: ${theme.deprecated_.fontWeights.semiBold};
    }
  `}
`;
