import { type FC, type PropsWithChildren } from 'react';
import { type DefaultTheme } from 'styled-components';

import { SegmentedProgressBar } from '@littleotter/zz-legacy-components';

import { interpolatingLinearFunction } from '$shared/utils/interpolatingLinearFunction';

import { Separator, StyledCategorization, TextContainer } from './styled';

type ColorKey = keyof DefaultTheme['deprecated_']['colors'];

type Colors = [belowThreshold: ColorKey, aboveThreshold: ColorKey];

const MISSING_CATEGORY = 'Missing';
const MISSING_COLOR = 'gray';
const NORMALIZED_MAX_SCORE = 100;
const NORMALIZED_THRESHOLD = 80;

export type DomainScoreProps = {
  label?: string;
  rawScore?: number;
  threshold: number;
  maxScore: number;
  lessThresholdLabel: string;
  greaterEqualThresholdLabel: string;
  disabled?: boolean;
};

// If the score is greater than or equal to the threshold, then we always show NORMALIZED_EXCEEDED_SCORE.
// Otherwise, we normalize the value between 0 and NORMALIZED_THRESHOLD.
export const DomainScore: FC<PropsWithChildren<DomainScoreProps>> = ({
  label,
  maxScore,
  rawScore,
  threshold,
  disabled,
  lessThresholdLabel,
  greaterEqualThresholdLabel,
}) => {
  const colors: Colors = ['green', 'red'];

  // Categorical score is in "exceeding" category if it is equal to or greater than the threshold.
  let categorization = MISSING_CATEGORY;
  let color: ColorKey = MISSING_COLOR;
  let normalizedProgress = 0;
  if (isRealScore(rawScore)) {
    const exceedsThreshold = rawScore >= threshold;
    categorization = exceedsThreshold ? greaterEqualThresholdLabel : lessThresholdLabel;
    color = colors[exceedsThreshold ? 1 : 0];

    // Things that exceed the threshold are NORMALIZED_EXCEEDED_SCORE, things that are not are squeezed between 0 and NORMALIZED_THRESHOLD
    normalizedProgress = interpolatingLinearFunction([
      [0, 0],
      [threshold, NORMALIZED_THRESHOLD],
      [maxScore, NORMALIZED_MAX_SCORE],
    ])(rawScore);
    // If the score exceeds the threshold and is equal to the normalized threshold, we have to
    // add 1 because the SegmentedProgressBar will not show the right color if it is equal
    // to the threshold.
    if (exceedsThreshold && normalizedProgress === NORMALIZED_THRESHOLD) {
      normalizedProgress += 1;
    }

    // If the score is 0, we add 1 so that we can show a bit of the bar to indicate "really low"
    if (normalizedProgress === 0) {
      normalizedProgress += 1;
    }
  }

  return (
    <div>
      <TextContainer>
        {label && (
          <>
            {label}
            <Separator />
          </>
        )}
        <StyledCategorization color={color}>{categorization}</StyledCategorization>
      </TextContainer>
      <SegmentedProgressBar
        size="small"
        progress={disabled ? 0 : normalizedProgress}
        segments={[
          { threshold: NORMALIZED_THRESHOLD, color: colors[0] },
          { threshold: NORMALIZED_MAX_SCORE, color: colors[1] },
        ]}
      />
    </div>
  );
};

const isRealScore = (score: number | undefined): score is number => score !== undefined;
