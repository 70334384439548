import { Capacitor } from '@capacitor/core';
import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Icon } from '@littleotter/zz-legacy-components';

const isOniOS = Capacitor.getPlatform() === 'ios';

type StyledHeaderProps = {
  hasHeader?: boolean;
};

const StyledHeader = styled.header<StyledHeaderProps>`
  padding: 1rem;
  ${isOniOS ? 'padding-top: 3rem;' : ''}
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: ${isOniOS ? 6 : 4}rem;
  width: 100vw;
  background-color: ${({ theme }) => theme.deprecated_.colors.text};
`;

const BackButton = styled(Button)`
  font-size: 2rem;
  position: absolute;
`;

const Content = styled.div`
  text-align: center;
`;

export const AssessmentHeader: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  return (
    <StyledHeader>
      <BackButton variant="clearStyles" onClick={() => history.goBack()}>
        <Icon name="ArrowLeft" />
      </BackButton>
      <Content>{children}</Content>
    </StyledHeader>
  );
};
