import { type FC, type PropsWithChildren } from 'react';

import { BrandCard, Link } from '@littleotter/zz-legacy-components';

import { supportProxyUrl } from '$shared/utils/proxy';

import { StyledSection } from './styled';

export const CustomerSupportCard: FC<PropsWithChildren> = () => {
  return (
    <StyledSection>
      <Link variant="clearStyles" href={supportProxyUrl}>
        <BrandCard>
          <strong>Customer Support</strong>
          <br />
          Do you need help with your account, billing, scheduling, or are you having technical issue with the Little
          Otter App?
        </BrandCard>
      </Link>
    </StyledSection>
  );
};
