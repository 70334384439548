import { math } from 'polished';
import styled from 'styled-components';

import { ControlledCollapse, SegmentedProgressBar } from '@littleotter/zz-legacy-components';

export const StyledTitle = styled.h5`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.25`)};
`;

export const StyledSubtitle = styled.p`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text};
`;

export const StyledCollapse = styled(ControlledCollapse)`
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.deprecated_.colors.lightGray};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis}`)};

  > button {
    align-items: flex-start;
  }
`;

export const StyledSegmentedProgressBar = styled(SegmentedProgressBar)`
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)} 0;
`;

export const StyledScoreTypical = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.green};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-weight: 600;
`;

export const StyledScoreConcerning = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.red};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-weight: 600;
`;

export const StyledScoreMissing = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.dimGray};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-weight: 600;
`;
