import { darken, math } from 'polished';
import styled from 'styled-components';

import { Link } from '@littleotter/zz-legacy-components';

export const Container = styled(Link).attrs({ variant: 'clearStyles' })`
  display: block;
  border-bottom: ${({ theme }) =>
    `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)} 0;

  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.white)};
  }

  &:active {
    background-color: ${({ theme }) => darken(0.2, theme.deprecated_.colors.white)};
  }

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  }
`;

export const ConversationMeta = styled.div`
  display: flex;
  padding: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const MetaLeftSection = styled.div`
  display: flex;
  overflow: hidden;
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;

export const ProfilePictureContainer = styled.div`
  flex-shrink: 0;
`;

export const MessageDescription = styled.div`
  min-width: 0;
`;

export const Title = styled.h5`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
`;

// multiline ellipsis code based on
// https://www.consolelog.io/multiple-line-ellipsis-css-effect/
export const LastMessage = styled.p<{ hasUnreadMessages: boolean }>`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  ${({ theme, hasUnreadMessages }) => (hasUnreadMessages ? '' : `color: ${theme.deprecated_.colors.gray}`)};

  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: ${({ theme }) => math(`${theme.deprecated_.fontSizes.small} * ${theme.deprecated_.lineHeight} * 2`)};
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MetaRightSection = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: flex-end;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
`;

export const LastActiveAt = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;

export const UnreadCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.deprecated_.colors.black};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  background-color: ${({ theme }) => theme.deprecated_.colors.yellow};
  padding: 4px 8px;
  border-radius: 6px;
`;

export const LoadingContainer = styled.div`
  text-align: center;
`;

export const OutOfOfficeLabel = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.darkestPurple};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding-bottom: ${({ theme }) => theme.spacing['04']};
`;
