import { createContext, useContext, type Dispatch, type FC, type PropsWithChildren, type SetStateAction } from 'react';

import { useSearchParams } from '@littleotter/zz-kit/react';

import { STORAGE_KEY, useSessionStorage } from '$shared/hooks/useStorage';

import { type ChildAuthorizationQuery_insuranceReferral as ChildAuthorization } from '../../../graphql/__generated__/ChildAuthorizationQuery';
import { type ProfileChildQuery_child } from '../../../graphql/__generated__/ProfileChildQuery';
import { type ProviderListQuery_insurancePartners } from '../../../graphql/__generated__/ProviderListQuery';
import { useViewer } from '../Viewer';
import { useChildAuthorization, type OnAddChildAuthorizationType } from './hooks/useChildAuthorization';
import { useChildInfo } from './hooks/useChildInfo';
import { useReferralProvider, type SetChildSelectedProviderType } from './hooks/useReferralProvider';

type ChildContextValue = {
  childInfo?: ProfileChildQuery_child;
  providerList?: ProviderListQuery_insurancePartners[];
  childSelectedProvider?: ProviderListQuery_insurancePartners;
  setChildSelectedProvider?: SetChildSelectedProviderType;
  childAuthorizationData?: ChildAuthorization | null;
  onAddChildAuthorization: OnAddChildAuthorizationType;
  isChildAuthorizationLoading?: boolean;
  setReferralUuid: Dispatch<SetStateAction<string>>;
  setReferralNumber: Dispatch<SetStateAction<string>>;
};

export type ChildAuthSearchParams = {
  uuid: string;
  referralNumber: string;
};

const ChildContext = createContext({} as ChildContextValue);

export const ChildProvider: FC<PropsWithChildren> = ({ children }) => {
  const [{ childId }] = useSearchParams<{ childId: string }>();

  const [referralUuid, setReferralUuid] = useSessionStorage(STORAGE_KEY.REFERRAL_UUID, '');
  const [referralNumber, setReferralNumber] = useSessionStorage(STORAGE_KEY.REFERRAL_NUMBER, '');
  const { loading, isAuthenticatedViewer } = useViewer();
  const isLoggedIn = !loading && isAuthenticatedViewer;
  const childInfo = useChildInfo(childId, isLoggedIn);
  const { providerList, childSelectedProvider, setChildSelectedProvider } = useReferralProvider({ isLoggedIn });
  const { childAuthorizationData, onAddChildAuthorization, isChildAuthorizationLoading } = useChildAuthorization({
    isLoggedIn,
    childReferralUuid: referralUuid,
    childReferralNumber: referralNumber,
  });

  const referralContextValue: ChildContextValue = {
    childInfo,
    providerList,
    childSelectedProvider,
    setChildSelectedProvider,
    childAuthorizationData,
    onAddChildAuthorization,
    isChildAuthorizationLoading,
    setReferralUuid,
    setReferralNumber,
  };
  return <ChildContext.Provider value={referralContextValue}>{children}</ChildContext.Provider>;
};

export const useChildReferral = () => useContext(ChildContext);
