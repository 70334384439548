import { type FC, type PropsWithChildren } from 'react';

import { Link } from '@littleotter/zz-legacy-components';

import anxietySrc from './assets/anxiety.svg';
import caregiverMentalHealthSrc from './assets/caregiverMentalHealth.svg';
import childEmotionalAndBehavioralHealthSrc from './assets/childEmotionalAndBehavioralHealth.svg';
import depressionSrc from './assets/depression.svg';
import familyDynamicsSrc from './assets/familyDynamics.svg';
import holidayStressSrc from './assets/holidayStress.svg';
import { CardContent, type CardContentProps } from './CardContent';
import { Card } from './styled';
import { type Category, type Risk } from './types';

const getContentPropsFromCategory = (category: Category): Omit<CardContentProps, 'risk'> =>
  ({
    ChildEmotionalAndBehavioralHealth: {
      outerTitle: 'Child Emotional & Behavioral Health',
      illustrationSrc: childEmotionalAndBehavioralHealthSrc,
    },
    CaregiverMentalHealthRisk: {
      outerTitle: 'Caregiver Mental',
      innerTitle: 'Health Risk',
      illustrationSrc: caregiverMentalHealthSrc,
    },
    HolidayStress: {
      innerTitle: 'Holiday Stress',
      illustrationSrc: holidayStressSrc,
    },
    FamilyDynamics: {
      innerTitle: 'Family Dynamics',
      illustrationSrc: familyDynamicsSrc,
    },
    Anxiety: {
      innerTitle: 'Anxiety',
      illustrationSrc: anxietySrc,
    },
    Depression: {
      innerTitle: 'Depression',
      illustrationSrc: depressionSrc,
    },
  })[category];

const Container: FC<PropsWithChildren<{ href?: string }>> = ({ href, children }) =>
  href ? (
    <Link isAnchor variant="clearStyles" href={href}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

export type SummaryCardProps = {
  category: Category;
  risk: Risk;
  href?: string;
};

export const SummaryCard: FC<PropsWithChildren<SummaryCardProps>> = ({ category, risk, href }) => {
  return (
    <Container href={href}>
      <Card>
        <CardContent risk={risk} {...getContentPropsFromCategory(category)} />
      </Card>
    </Container>
  );
};
