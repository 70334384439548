import { useCallback, useState, type FC, type MouseEventHandler, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Header, Link } from '@littleotter/zz-legacy-components';

import { useEventTracking } from '$services/event-tracking';
import { useGetFmhcsWithRetakeWindow } from '$shared/scenarios';

import IntakeQBookingWidget from '../../../../components/IntakeQBookingWidget';
import { type WidgetBookedMessageData } from '../../../../components/IntakeQBookingWidget/types';
import { routes } from '../../../../routes';
import { ExpertDetailsModal } from './components';
import {
  Card,
  CardDetailsList,
  CardDetailsListItem,
  Footer,
  StyledHeader,
  StyledParagraph,
  StyledPricingParagraph,
} from './styled';

export type SessionDetailsContentProps = {
  clientId: string;
  clientName: string;
  clientEmail: string;
  clientPhone: string | null;
  serviceId: string;
  price: number;
  durationInMinutes: number;
  onLoaded?: () => void;
  onScheduled?: (appointmentData: WidgetBookedMessageData) => void;
};
export const SessionDetailsContent: FC<PropsWithChildren<SessionDetailsContentProps>> = ({
  clientId,
  clientName,
  clientEmail,
  clientPhone,
  serviceId,
  price,
  durationInMinutes,
  onLoaded,
  onScheduled,
}) => {
  const [isExpertDetailsModalOpen, setIsExpertDetailsModalOpen] = useState(false);
  const history = useHistory();
  const openExpertDetailsModal = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault();
    setIsExpertDetailsModalOpen(true);
  }, []);
  const { latestSubmitted } = useGetFmhcsWithRetakeWindow();
  const { track } = useEventTracking();
  const closeExpertDetailsModal = useCallback(() => {
    setIsExpertDetailsModalOpen(false);
  }, []);

  const handleNavigateToCareDen = () => {
    track('CLICKED_SKIP_TO_CARE_DEN');
    history.push(routes.care.home.path);
  };

  const handleNavigateToCheckup = () => {
    track('CLICKED_SKIP_TO_ASSESSMENT');
    history.push(routes.checkup.base.path);
  };
  return (
    <>
      <Header as="h3">Confirm session details</Header>
      <Card>
        <StyledHeader as="h5">Kickoff Session - ${price}</StyledHeader>
        <CardDetailsList>
          <CardDetailsListItem>
            <span>{durationInMinutes} min Zoom video session (caregivers only)</span>
          </CardDetailsListItem>
          <CardDetailsListItem>
            Connect with a{' '}
            <Link onClick={openExpertDetailsModal}>
              <strong>child mental health expert</strong>
            </Link>{' '}
          </CardDetailsListItem>
          <CardDetailsListItem>Review your family's custom mental health report</CardDetailsListItem>
          <CardDetailsListItem>Schedule your child's first session with a provider.</CardDetailsListItem>
        </CardDetailsList>
        <StyledPricingParagraph>
          If paying out-of-network, our therapy sessions (45min) are $180 per session for a 4 session bundle. For more
          information about pricing please checkout our FAQ <a href="https://www.littleotterhealth.com/pricing">here</a>
          .
        </StyledPricingParagraph>
      </Card>

      <IntakeQBookingWidget
        clientId={clientId}
        clientEmail={clientEmail}
        clientName={clientName}
        clientPhone={clientPhone}
        config={{
          serviceId,
          onLoaded,
          callback: onScheduled,
        }}
      />
      <StyledParagraph>
        {latestSubmitted ? (
          <Button variant="link" onClick={handleNavigateToCareDen}>
            <strong>Skip booking & go to Care Den</strong>
          </Button>
        ) : (
          <Button variant="link" onClick={handleNavigateToCheckup}>
            <strong>Skip booking & take assessment</strong>
          </Button>
        )}
      </StyledParagraph>
      <Footer>
        <p>
          You will only being charged for one ${price} ({durationInMinutes} min) kickoff session. Canceling within 24
          hours of the appointment will incur 100% charge.
        </p>
      </Footer>
      <ExpertDetailsModal isOpen={isExpertDetailsModalOpen} onClose={closeExpertDetailsModal} />
    </>
  );
};
