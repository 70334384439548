import styled from 'styled-components';

import { HTMLContainer } from '@littleotter/zz-legacy-components';

export const StyledHtmlContainer = styled(HTMLContainer)`
  /* Collapsible content max height minus vertical padding */
  max-height: calc(1500px - 1rem);
  overflow-y: auto;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  li {
    list-style: none;
  }
`;
