import { useQuery } from '@apollo/client';
import { math } from 'polished';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { stringifySearchParams } from '@littleotter/zz-kit/utils';
import { Header, HorizontalScrollingContainer, Link } from '@littleotter/zz-legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { MarginChildren } from '../../../components';
import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type LibraryHomeQuery,
  type LibraryHomeQuery_viewer_family_children_worryDomains_edges_node,
} from '../../../graphql/__generated__/LibraryHomeQuery';
import { MissingQueryDataError } from '../../../graphql/errors';
import { LIBRARY_HOME_QUERY } from '../../../graphql/library-home';
import { routes } from '../../../routes';
import Resources from '../components/Resources';
import WorryDomains from '../components/WorryDomains';

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  }
`;

const FloatRightLink = styled(Link)`
  float: right;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
`;

const IncreasedHeightScrollingContainer = styled(HorizontalScrollingContainer)`
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 6`)};
  > div {
    height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5`)};
  }
`;

export const LibraryHome = () => {
  const { data, loading, error } = useQuery<LibraryHomeQuery>(LIBRARY_HOME_QUERY);
  useGraphQLErrorHandling(error);

  const history = useHistory();

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('LibraryHomeQuery');

  const exploreWorryDomains = data.worryDomains?.edges ?? [];
  const halfwayPointOfExploreWorryDomains = Math.floor(exploreWorryDomains.length / 2);
  const favorites = data.viewer?.favorites.edges ?? [];

  const childWorryDomains =
    data.viewer?.family?.children.reduce<LibraryHomeQuery_viewer_family_children_worryDomains_edges_node[]>(
      (worryDomainsAccumulator, child) => {
        const currentNodes = child.worryDomains.edges
          .map((edge) => edge?.node)
          .filter((node): node is LibraryHomeQuery_viewer_family_children_worryDomains_edges_node => !!node);
        return [...worryDomainsAccumulator, ...currentNodes];
      },
      []
    ) || [];

  return (
    <Container>
      <MarginChildren>
        <Header as="h4">
          <span>Explore</span>
          <FloatRightLink href={routes.library.explore.url()}>See All</FloatRightLink>
        </Header>
        <p>Learn more about Little Otter's focus areas and find the help you need.</p>
        <div>
          <IncreasedHeightScrollingContainer>
            <WorryDomains
              worryDomains={exploreWorryDomains.slice(0, halfwayPointOfExploreWorryDomains)}
              onClick={(worryDomainId) => () =>
                history.push({
                  pathname: routes.library.explore.url(),
                  search: stringifySearchParams({
                    worryDomainIds: worryDomainId ? [String(worryDomainId)] : undefined,
                  }),
                })
              }
            />
          </IncreasedHeightScrollingContainer>
          <IncreasedHeightScrollingContainer>
            <WorryDomains
              worryDomains={exploreWorryDomains.slice(halfwayPointOfExploreWorryDomains)}
              onClick={(worryDomainId) => () =>
                history.push({
                  pathname: routes.library.explore.url(),
                  search: stringifySearchParams({
                    worryDomainIds: worryDomainId ? [String(worryDomainId)] : undefined,
                  }),
                })
              }
            />
          </IncreasedHeightScrollingContainer>
        </div>
      </MarginChildren>
      <MarginChildren>
        <Header as="h4">
          <span>For You</span>
          <FloatRightLink href={routes.library.forYou.url()}>See All</FloatRightLink>
        </Header>
        <p>Curated activities, articles, and tools based on your family's needs.</p>

        {childWorryDomains.map(
          (worryDomain) =>
            worryDomain?.resourcesByTag?.edges &&
            worryDomain?.resourcesByTag?.edges.length > 0 && (
              <Fragment key={worryDomain.id}>
                <Header as="h5">{worryDomain.name}</Header>
                <MarginChildren>
                  <Resources horizontalScrolling resources={worryDomain.resourcesByTag.edges} />
                </MarginChildren>
              </Fragment>
            )
        )}
      </MarginChildren>
      <MarginChildren>
        <Header as="h4">
          <span>Saved</span>
          <FloatRightLink href={routes.library.saved.url()}>See All</FloatRightLink>
        </Header>
        <p>Resources that you previously saved will be displayed here.</p>
        <Resources horizontalScrolling resources={favorites} isSaved />
      </MarginChildren>
    </Container>
  );
};
