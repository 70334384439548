import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Header, Icon, Link } from '@littleotter/zz-legacy-components';

import { ReactComponent as OpenBox } from './box.svg';

const CenteredMarginChildren = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  > * {
    max-width: 80%;
  }
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  }
`;

const StyledBox = styled(OpenBox)`
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 12`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 12`)};
`;

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

const StyledButton = styled(Link)`
  background-color: #365467;
  position: relative;
`;

const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.deprecated_.colors.text};
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  > svg {
    width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
    height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
    > path {
      fill: ${({ theme }) => theme.deprecated_.colors.white};
    }
  }
`;

const LabelContainer = styled.div`
  margin-left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
`;

export const ConversationListEmptyState: FC<PropsWithChildren> = () => {
  // uses next-gen schedule appointments page
  const scheduleHref = `${process.env.NEXT_GEN_PROXY_URL}/care/appointments`;

  return (
    <CenteredMarginChildren>
      <StyledBox />
      <Header as="h4" useBodyFont>
        Nothing Here
      </Header>
      <StyledParagraph>
        There are no messages to show here. You need to schedule your first appointment to gain access to messaging.
      </StyledParagraph>
      <StyledParagraph>
        Need help getting started? Reach out to{' '}
        <Link isAnchor href="mailto:help@littleotterhealth.com">
          help@littleotterhealth.com
        </Link>{' '}
        and a team member will respond as soon as possible.{' '}
      </StyledParagraph>
      <StyledButton variant="primary" href={scheduleHref}>
        <IconContainer>
          <Icon name="Calendar" />
        </IconContainer>
        <LabelContainer>Schedule Appointment</LabelContainer>
      </StyledButton>
    </CenteredMarginChildren>
  );
};
