import { type FC, type PropsWithChildren } from 'react';

import { Link } from '@littleotter/zz-legacy-components';

import { routes } from '../../../../../../routes';
import { ReportGrayCard } from '../components/ReportGrayCard';

export const CareDenSavedReportCard: FC<PropsWithChildren> = () => {
  const url = routes.care.base.path;
  return (
    <ReportGrayCard title="" icon="💾">
      <p>
        <strong>
          This report is saved in your{' '}
          <Link variant="link" isAnchor href={url}>
            Care Den
          </Link>
        </strong>
      </p>
    </ReportGrayCard>
  );
};
