import { math, rgba, stripUnit } from 'polished';
import { useCallback, type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { List } from '@littleotter/zz-legacy-components';

import { assertNever } from '../../../FmhcCheckup/mappers/utils';
import {
  isCaregiverSubject,
  isChildSubject,
  SubjectType,
  type ChildSubject,
  type Subject,
} from '../../../FmhcCheckup/types';
import { type Score, type SubjectReportCollection } from '../types';
import { DomainItem, DomainScore, SummaryContent, WorryDomainItem, type DomainCardType } from './components';
import { PaywallReportCard } from './components/PaywallReportCard';
import { interpolateChildVariables } from './hooks/mappers/childReports';
import {
  DomainScoresContainer,
  StyledReportSubjectContainer,
  StyledSecondaryTitle,
  SummaryProgressCardContainer,
} from './styled';

const DomainList = styled(List).attrs({ variant: 'connected-disc' })`
  --decoration-color: #91a5b3;
  --decoration-size: 19px;
  --decoration-line-color: ${({ theme }) => rgba(`${theme.deprecated_.colors.japaneseIndigo}`, 0.4)};
  --line-height: ${({ theme }) =>
    stripUnit(math(`${theme.deprecated_.lineHeight} * ${theme.deprecated_.fontSizes.h5}`))};
  --item-gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
`;

const ReportForSubjectCTAContainer = styled.div`
  padding-bottom: 2rem;
`;

const getRecapTitle = (subject: Subject) => {
  if (isChildSubject(subject)) {
    return `${subject.firstName}'s Recap`;
  }
  if (isCaregiverSubject(subject)) {
    return 'Your Recap';
  }
  return "Your Family's Recap";
};

const getWorryDomainsMessage = (subject: Subject): string => {
  const messagePrefix = 'Worries you shared about';
  switch (subject.subjectType) {
    case SubjectType.Child: {
      const childName = (subject as ChildSubject).firstName;
      return `${messagePrefix} ${childName}`;
    }
    case SubjectType.Caregiver: {
      return `${messagePrefix} yourself`;
    }
    case SubjectType.Family: {
      return `${messagePrefix} your family`;
    }
    default: {
      return assertNever(subject.subjectType);
    }
  }
};

export type ReportForSubjectProps = {
  isPaywalled?: boolean;
  reportForSubject: SubjectReportCollection;
  isFreeAccount: boolean;
  isInAllowedState: boolean;
  goScheduleWelcomeCall: () => void;
  goPurchaseReport?: () => void;
  onDomainCardClick?: (domainName: string, cardType: DomainCardType) => void;
};

export const ReportForSubject: FC<PropsWithChildren<ReportForSubjectProps>> = ({
  isPaywalled,
  reportForSubject,
  isFreeAccount,
  isInAllowedState,
  goScheduleWelcomeCall,
  goPurchaseReport,
  onDomainCardClick,
}) => {
  const isFreeChildOrCaregiver =
    [SubjectType.Caregiver, SubjectType.Child].includes(reportForSubject.subject.subjectType) && isFreeAccount;

  const availableDomains = reportForSubject.domainReports.filter((domainItem) => !domainItem.isDisabled);
  const unavailableDomains = reportForSubject.domainReports.filter((domainItem) => domainItem.isDisabled);
  const unavailableDomainNames = unavailableDomains.map((domainItem) => domainItem.name);

  const handleDomainCardClick = useCallback(
    (domainName: string) => (cardType: DomainCardType) => {
      onDomainCardClick?.(domainName, cardType);
    },
    [onDomainCardClick]
  );

  const interpolateChildName = (score: Score): Score => {
    if (!isChildSubject(reportForSubject.subject)) {
      return score;
    }
    return {
      ...score,
      label: interpolateChildVariables(score.label, { child_name: reportForSubject.subject.firstName }),
    };
  };

  return (
    <StyledReportSubjectContainer>
      <StyledSecondaryTitle>{reportForSubject.title}</StyledSecondaryTitle>
      <DomainList>
        {reportForSubject.worryDomains.length > 0 && (
          <WorryDomainItem
            message={getWorryDomainsMessage(reportForSubject.subject)}
            worries={reportForSubject.worryDomains}
          />
        )}
        {availableDomains.map((item) => (
          <DomainItem
            key={`domain-item-${reportForSubject.subject.subjectType}-${item.name}`}
            name={item.name}
            scores={[item.score]}
            explanation={item.explanation}
            callToAction={item.callToAction}
            onDomainCardClick={handleDomainCardClick(item.name)}
          />
        ))}
        {(isFreeChildOrCaregiver || isPaywalled) && reportForSubject.subject.subjectType !== 'Family' && (
          <ReportForSubjectCTAContainer>
            <PaywallReportCard
              subject={reportForSubject.subject}
              domainNames={unavailableDomainNames}
              goScheduleWelcomeCall={goScheduleWelcomeCall}
              goPurchaseReport={goPurchaseReport}
              isInAllowedState={isInAllowedState}
            />
          </ReportForSubjectCTAContainer>
        )}
        {unavailableDomains.map((item) => (
          <DomainItem
            key={`domain-item-${reportForSubject.subject.subjectType}-${item.name}`}
            name={item.name}
            isDisabled
            scores={[item.score]}
            explanation={item.explanation}
            callToAction={item.callToAction}
          />
        ))}
        {reportForSubject.impactReport && (
          <DomainItem
            name="Impact"
            scores={reportForSubject.impactReport.subScores.map(interpolateChildName)}
            explanation={reportForSubject.impactReport.explanation}
            callToAction={reportForSubject.impactReport.callToAction}
            onDomainCardClick={handleDomainCardClick('Impact')}
          />
        )}
      </DomainList>
      <SummaryProgressCardContainer>
        <SummaryContent title={getRecapTitle(reportForSubject.subject)}>
          <DomainScoresContainer>
            {reportForSubject.domainReports.map(
              ({ name, isDisabled, score: { categorizations, label, maxScore, rawScore, threshold } }) => (
                <DomainScore
                  key={`recap-${name}-${label}`}
                  label={label}
                  rawScore={rawScore}
                  threshold={threshold}
                  maxScore={maxScore}
                  lessThresholdLabel={categorizations.belowThreshold}
                  greaterEqualThresholdLabel={categorizations.aboveThreshold}
                  disabled={isDisabled}
                />
              )
            )}
          </DomainScoresContainer>
        </SummaryContent>
      </SummaryProgressCardContainer>
    </StyledReportSubjectContainer>
  );
};
