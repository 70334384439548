import { type FC, type PropsWithChildren } from 'react';

import { Link } from '@littleotter/zz-legacy-components';

import { StyledAudio } from './styled';

type AudioElementProps = {
  mediaUrl: string;
};

export const AudioElement: FC<PropsWithChildren<AudioElementProps>> = ({ mediaUrl }) => (
  <StyledAudio controls src={mediaUrl}>
    <p>
      Your browser doesn't support audio. Here is a{' '}
      <Link isAnchor href={mediaUrl} target="_blank">
        Link to Download Audio
      </Link>{' '}
      instead.
    </p>
  </StyledAudio>
);
