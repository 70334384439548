import { math } from 'polished';
import { type FC, type MouseEventHandler, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Icon } from '@littleotter/zz-legacy-components';

import { WorryDomainIcon, type WorryDomainShortName } from '../WorryDomainIcon';

const Wrapper = styled.div`
  box-shadow: 0px 26px 60px rgba(0, 0, 0, 0.05);
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
`;

const Card = styled.div`
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5`)};
  display: flex;
  justify-content: space-between;
  border: ${({ theme }) => `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} transparent`};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.deprecated_.border.color};
    box-shadow: ${({ theme }) => `0 4px 8px ${theme.deprecated_.colors.lightGray}`};
  }
`;

const IconContainer = styled.div`
  width: ${({ theme }) => math(`${theme.deprecated_.fontSizes.larger} * 1.75`)};
  height: 100%;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.larger};
  margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  padding: ${({ theme }) => `${math(`${theme.deprecated_.sizeBasis} * 1.5`)} ${theme.deprecated_.sizeBasis}`};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  letter-spacing: 0.03em;
`;

const BigText = styled.div`
  margin: ${({ theme }) => `0 ${theme.deprecated_.sizeBasis}`};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.large};
  color: ${({ theme }) => theme.deprecated_.colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type WorryDomainCardProps = {
  onClick: MouseEventHandler;
  worryDomainShortName: WorryDomainShortName;
};

export const WorryDomainCard: FC<PropsWithChildren<WorryDomainCardProps>> = ({
  children,
  onClick,
  worryDomainShortName,
}) => (
  <Wrapper>
    <Card onClick={onClick}>
      <IconContainer>
        <WorryDomainIcon worryDomainShortName={worryDomainShortName} />
      </IconContainer>
      <Content>{children}</Content>
      <BigText>
        <Icon name="ChevronRight" />
      </BigText>
    </Card>
  </Wrapper>
);
