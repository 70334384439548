import { type FC, type PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FullButton } from '@littleotter/zz-legacy-components';

import { getLocationDescriptor, getRedirectValue } from '$shared/utils/rerouter/rerouter';

import { routes } from '../../../../routes';
import {
  StyledActionsWrapper,
  StyledContentWrapper,
  StyledImage,
  StyledPrimaryText,
  StyledSecondaryText,
  StyledTextWrapper,
} from './styled';

interface LocationState {
  from: string;
}

export const ReferralConfirmation: FC<PropsWithChildren> = () => {
  const history = useHistory();
  const { state } = useLocation<LocationState>();

  const redirectValue = getRedirectValue();

  // TODO (abranaf): review this redirection stuff once we consolidate the different child forms (PD-875)
  // If the redirect value is any of the profile pages then we return them there instead of the child summary page
  const shouldSkipSummary = redirectValue && redirectValue.includes(routes.profile.base.path);
  const goNextPage = () => {
    const nextPage = shouldSkipSummary
      ? redirectValue
      : getLocationDescriptor({
          route: routes.child.summary,
          forward: true,
          referrerLocation: state?.from,
        });
    history.push(nextPage);
  };

  return (
    <>
      <StyledContentWrapper>
        <StyledImage src="./assets/Family_otters.svg" alt="Image of otters family" />
        <StyledTextWrapper>
          <StyledPrimaryText>We’ve got you covered!</StyledPrimaryText>
          <StyledSecondaryText>
            Your coverage has been applied to your Little Otter family account. For more information on co-pays and
            coverage, please reach out to your insurance provider or referring partner.
          </StyledSecondaryText>
        </StyledTextWrapper>
      </StyledContentWrapper>
      <StyledActionsWrapper>
        <FullButton size="large" variant="secondary" onClick={goNextPage}>
          Continue
        </FullButton>
      </StyledActionsWrapper>
    </>
  );
};
