import { type DetailedHTMLProps, type FC, type InputHTMLAttributes, type PropsWithChildren } from 'react';
import { type FieldErrors } from 'react-hook-form6';
import styled from 'styled-components';

import { Icon } from '@littleotter/zz-legacy-components';

import { HiddenInput, ProfileInputIconSpan, StyledProfilePictureInput } from './styled';

const StyledError = styled.small`
  display: block;
  color: ${(props) => `${props.theme.deprecated_.colors.red}`};
`;

type ProfilePictureInputExtraProps = {
  name: string;
  errors?: FieldErrors;
};

export const ProfilePictureInput: FC<
  PropsWithChildren<
    Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'> &
      ProfilePictureInputExtraProps
  >
> = ({ name, errors, ...props }) => (
  // label is associated with HiddenInput

  <>
    <StyledProfilePictureInput>
      <ProfileInputIconSpan>
        <Icon name="Edit" />
      </ProfileInputIconSpan>{' '}
      Edit
      <HiddenInput type="file" id={name} name={name} accept=".jpg,.jpeg,.png" {...props} />
    </StyledProfilePictureInput>
    <StyledError>{errors?.[name]?.message}</StyledError>
  </>
);
