import { useSingletonEffect } from '@littleotter/zz-kit/react';
import { removeInvalidValues } from '@littleotter/zz-kit/utils';
import {
  ConsoleHandler,
  createClientLogService,
  createEnvironmentService,
  createEventTrackingService,
  DataDogBrowserHandler,
} from '@littleotter/zz-platform';
import { createSentryReactInitializer } from '@littleotter/zz-platform/react';
import { type ILogHandler } from '@littleotter/zz-platform/src/svc/logging/types';

import { initializeGTagManager } from '$services/analytics';

export const env = createEnvironmentService();

const { sentry, SentryErrorBoundary, SentryLogHandler } = createSentryReactInitializer(env);

const getLoggingHandlers = (): ILogHandler[] => {
  if (env.mode === 'test') {
    return [];
  }

  const consoleHandler = env.isLocalEnvironment ? new ConsoleHandler() : undefined;
  const datadogHandler = new DataDogBrowserHandler();
  return removeInvalidValues([consoleHandler, datadogHandler, new SentryLogHandler()]);
};

export const logger = createClientLogService(env, getLoggingHandlers());

export const _eventTracker = createEventTrackingService(env, logger);

// controlled init ordering (to be consumed in index.tsx)
export const init = () => {
  // eslint-disable-next-line no-console
  console.debug('[bootstrap.init] Initializing application bootstrap');
  sentry.init();
  logger.init();
};

/**
 * App Lifecycle: On Mount
 */
export const useMountBootstrap = () => {
  useSingletonEffect(() => {
    logger.debug('[bootstrap.app-lifecycle.mount] Bootstrapping on application mount hook');
  });

  initializeGTagManager();

  _eventTracker.init();
};

// Additional exports to expose to app
export { SentryErrorBoundary };
