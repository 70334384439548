import styled from 'styled-components';

import { HTMLContainer } from '@littleotter/zz-legacy-components';

export const Root = styled.div`
  overflow: hidden;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing['06']};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing['03']};
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text};
  margin-left: ${({ theme }) => theme.spacing['05']};
`;

export const HtmlContainer = styled(HTMLContainer)`
  overflow-y: auto;

  ul {
    margin-bottom: 0;
  }

  p {
    padding-left: ${({ theme }) => theme.spacing['02']};
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
