import { type FC, type PropsWithChildren } from 'react';

import { HTMLContainer } from '@littleotter/zz-legacy-components';

import { CollapseWithIcon } from '../../components';
import { SubSectionHeader } from './styled';

const Container: FC<PropsWithChildren<{ isSection: boolean }>> = ({ isSection, children }) =>
  isSection ? <section>{children}</section> : <div>{children}</div>;

type CollapseSectionProps = {
  sectionTitle?: string;
  meaningCustomLabel?: string;
  whatICanDoToSupportCustomLabel?: string;
  activityToDoCustomLabel?: string;
  meaningContent: string;
  whatICanDoToSupportContent: string;
  activityToDoContent: string;
};

export const CollapseSection: FC<PropsWithChildren<CollapseSectionProps>> = ({
  sectionTitle,
  meaningCustomLabel,
  whatICanDoToSupportCustomLabel,
  activityToDoCustomLabel,
  meaningContent,
  whatICanDoToSupportContent,
  activityToDoContent,
}) => {
  return (
    <Container isSection={!!sectionTitle}>
      {sectionTitle && <SubSectionHeader>{sectionTitle}</SubSectionHeader>}
      <CollapseWithIcon iconName="Message" label={meaningCustomLabel ?? 'What does this mean?'}>
        <HTMLContainer content={meaningContent} />
      </CollapseWithIcon>
      <CollapseWithIcon iconName="Bulb" label={whatICanDoToSupportCustomLabel ?? 'What can I do?'}>
        <HTMLContainer content={whatICanDoToSupportContent} />
      </CollapseWithIcon>
      <CollapseWithIcon
        iconName="Heart"
        label={activityToDoCustomLabel ?? 'Activity to do with your child'}
        defaultOpen
      >
        <HTMLContainer content={activityToDoContent} />
      </CollapseWithIcon>
    </Container>
  );
};
