import { math } from 'polished';
import styled from 'styled-components';

import { Header } from '@littleotter/zz-legacy-components';

export const Title = styled(Header).attrs({ as: 'h2' })`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.5`)};
  line-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
`;

export const SubTitle = styled.p`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text}CC;
`;
