import { useMutation, useQuery } from '@apollo/client';
import { useStateMachine } from 'little-state-machine';
import { useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';

import { Header } from '@littleotter/zz-legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { MarginChildren, SubText } from '../../../components';
import { PageWideLoading } from '../../../components/PageWideLoading';
import { Question } from '../../../components/Survey/Question';
import {
  type SubmitSurveyResponsesMutation,
  type SubmitSurveyResponsesMutationVariables,
} from '../../../graphql/__generated__/SubmitSurveyResponsesMutation';
import {
  type TantrumSurvey,
  type TantrumSurvey_survey,
  type TantrumSurvey_survey_questions_answers,
} from '../../../graphql/__generated__/TantrumSurvey';
import { MissingQueryDataError } from '../../../graphql/errors';
import { SUBMIT_SURVEY_RESPONSES_MUTATION } from '../../../graphql/survey';
import { TANTRUM_SURVEY } from '../../../graphql/tantrum-survey';
import { routes } from '../../../routes';
import { TantrumButton, updateState, type TantrumState } from './components/Tantrum';

const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: calc(3 * ${({ theme }) => theme.deprecated_.sizeBasis});
  padding: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
`;

const SurveyBody: FC<PropsWithChildren<{ survey: TantrumSurvey_survey }>> = ({ survey }) => {
  const history = useHistory();
  const [submitSurveyResponse, { loading }] = useMutation<
    SubmitSurveyResponsesMutation,
    SubmitSurveyResponsesMutationVariables
  >(SUBMIT_SURVEY_RESPONSES_MUTATION);
  const [currentQuestion, setCurrentQuestion] = useState(survey.questions[0]);
  const [lastAnswer, setLastAnswer] = useState<TantrumSurvey_survey_questions_answers | null>(null);
  const [currentFollowupQuestionIndex, setCurrentFollowupQuestionIndex] = useState<number>(-1);
  const { action, state } = useStateMachine<TantrumState>(updateState);

  useEffect(() => {
    if (survey) {
      const newSurvey = {
        ...survey,
        worryDomain: {
          ...survey.worryDomain,
          name: 'Tantrums',
          shortName: 'anger_aggression',
        },
      };
      return action({ survey: newSurvey });
    }
  }, [survey, action]);

  const { child } = state.tantrumData;

  const handleQuestionResponse = (value: string) => {
    const { surveyResponses } = state.tantrumData;
    const indexOfCurrentQuestion = surveyResponses.findIndex((response) => response.questionPk === currentQuestion.pk);
    const currentAnswer = currentQuestion?.answers?.find((answer) => answer.pk === Number(value));
    if (!currentAnswer) {
      return;
    }
    const newResponseObj = {
      questionPk: currentQuestion.pk,
      answerPk: currentAnswer.pk,
      score: currentAnswer.value,
    };
    if (indexOfCurrentQuestion > -1) {
      surveyResponses[indexOfCurrentQuestion] = newResponseObj;
    } else {
      surveyResponses.push(newResponseObj);
    }
    if (currentAnswer && currentAnswer.followupQuestions.length) setLastAnswer(currentAnswer);
    action({ surveyResponses });
  };

  const handleGoToNext = async () => {
    if (lastAnswer && lastAnswer.followupQuestions.length > currentFollowupQuestionIndex + 1) {
      const question = survey.followUpQuestions[currentFollowupQuestionIndex + 1];
      setCurrentFollowupQuestionIndex(currentFollowupQuestionIndex + 1);
      setCurrentQuestion(question);
      return;
    }

    const surveyResponseForInput = state.tantrumData.surveyResponses.map((response) => ({
      questionPk: response.questionPk,
      answerPk: response.answerPk,
    }));
    const response = await submitSurveyResponse({
      variables: {
        input: {
          surveys: [{ surveyId: survey.pk, questionResponses: surveyResponseForInput }],
          email: state.tantrumData.email,
        },
      },
    });

    const submitSurveyResponseResult = response.data?.submitSurveyResponse;
    const surveyResponseIds = submitSurveyResponseResult?.surveyResponses?.map((surveyResponse) => surveyResponse.pk);

    action({ surveyResponseIds });

    return history.push({
      pathname: routes.tantrums.feedback.url(),
      search: history.location.search,
    });
  };

  return (
    <SurveyContainer>
      <MarginChildren>
        {currentQuestion === survey.questions[0] && (
          <>
            <Header as="h3">Now tell us a bit about {child.firstName}'s tantrums.</Header>
            <SubText>
              Whatever the results, Little Otter is here to help you support your child’s healthy emotional and
              behavioral development!
            </SubText>
          </>
        )}
        <SwitchTransition>
          <CSSTransition key={currentQuestion.pk} timeout={500} classNames="fade">
            <Question
              childName={child.firstName}
              childGender={null}
              question={currentQuestion}
              onQuestionResponse={handleQuestionResponse}
            />
          </CSSTransition>
        </SwitchTransition>
      </MarginChildren>
      <TantrumButton variant="primary" onClick={handleGoToNext} isLoading={loading}>
        Next
      </TantrumButton>
    </SurveyContainer>
  );
};

export const TantrumSurveyWithPrerequisites = () => {
  const { data, loading, error } = useQuery<TantrumSurvey>(TANTRUM_SURVEY);
  useGraphQLErrorHandling(error);
  const {
    state: { tantrumData },
  } = useStateMachine<TantrumState>();

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('TantrumSurvey');

  const childEntered = !!(tantrumData.child.firstName && tantrumData.child.dateOfBirth);

  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: !childEntered || !data,
          route: routes.tantrums.home,
          noRedirect: true,
        },
      ]}
      loading={false}
    >
      <SurveyBody survey={data.survey} />
    </WithPrerequisites>
  );
};
