import { ClerkLoaded, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import Cookies from 'js-cookie';
import { type PropsWithChildren } from 'react';

import { useSingletonEffect } from '@littleotter/zz-kit/react';

import { logger } from '$services/logging';
import { useViewer } from '$shared/contexts/Viewer';

import { loginProxyUrl } from '../../shared/utils/proxy';
import { MetadataRoute, type MetadataRouteProps } from '../MetadataRoute';

// ! DEPENDENCY: this has to match the cookie in apps/member/src/core/configs/cookies.ts
const POST_LOGIN_REDIRECT_COOKIE = 'post_login_redirect';
const COOKIE_OPTIONS = { domain: '.littleotterhealth.com' } as const;

export const AuthenticatedRoute = ({ children, ...rest }: PropsWithChildren<MetadataRouteProps>) => {
  return (
    <MetadataRoute {...rest}>
      <ClerkAuthenticatedRoute>{children}</ClerkAuthenticatedRoute>
    </MetadataRoute>
  );
};

export const ClerkAuthenticatedRoute = ({ children }: PropsWithChildren) => {
  useSingletonEffect(() => {
    logger.debug('[ClerkAuthenticatedRoute] Accessing authenticated route');
  });

  return (
    <ClerkLoaded>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <SignedIn>{children}</SignedIn>
    </ClerkLoaded>
  );
};

export const DeprecatedAuthenticatedRoute = ({ children }: PropsWithChildren) => {
  const { isAuthenticatedViewer, loading } = useViewer();

  return (
    <>
      {(() => {
        // Don't render anything until we know the user
        if (loading) {
          return null;
        }

        // only render `children` if the user is authenticated
        if (isAuthenticatedViewer) {
          // clear the post_login_redirect cookie when rendering authenticated routes
          Cookies.remove(POST_LOGIN_REDIRECT_COOKIE, COOKIE_OPTIONS);
          return children;
        }

        window.location.href = loginProxyUrl;
        return null;
      })()}
    </>
  );
};
