import { useQuery } from '@apollo/client';
import { math, rgba } from 'polished';
import { useState, type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useSearchParams } from '@littleotter/zz-kit/react';
import { Button, Header, Icon, Input, RemovableElement, Tag, TagsContainer } from '@littleotter/zz-legacy-components';

import { type LibraryWorryDomains } from '../../../graphql/__generated__/LibraryWorryDomains';
import { type LibraryWorryDomainsAndTags } from '../../../graphql/__generated__/LibraryWorryDomainsAndTags';
import { type SearchResources, type SearchResourcesVariables } from '../../../graphql/__generated__/SearchResources';
import {
  GET_ALL_WORRY_DOMAINS,
  GET_ALL_WORRY_DOMAINS_AND_TAGS,
  SEARCH_RESOURCES,
} from '../../../graphql/library-explore';
import { Filters, type FiltersList } from '../components/Filters';
import Resources from '../components/Resources';
import { WorryDomainOrResourceTag } from '../components/WorryDomainOrResourceTag';
import WorryDomains from '../components/WorryDomains';
import { getHeaderText } from './utils';

const MarginChildren = styled.div`
  margin-top: ${(props) => props.theme.deprecated_.sizeBasis};
  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

const StyledInput = styled(Input)`
  padding-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.5`)};
`;

const StyledButton = styled(Button)`
  position: absolute;
  padding: 0;
  min-width: 0;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.5`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.5`)};
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.5`)};

  svg {
    margin-right: 0;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 1.5`)} !important;
`;

const StyledTag = styled(Tag)`
  background-color: ${({ theme }) => rgba(theme.deprecated_.colors.yellow, 0.4)};
`;

const getArrayFromSearchParam = (searchParam: string | string[] | undefined): string[] => {
  if (!searchParam) return [];
  if (Array.isArray(searchParam)) return searchParam;
  return [searchParam];
};

type ExploreSearchParams = {
  search?: string;
  worryDomainIds?: string | string[];
  tagIds?: string | string[];
};

export const Explore: FC<PropsWithChildren> = () => {
  const [{ search, worryDomainIds, tagIds }, { pushSearchParams }] = useSearchParams<ExploreSearchParams>();

  const [filters, setFilters] = useState<FiltersList>({
    ageRanges: [],
    worryDomain: undefined,
    tags: [],
  });
  const [showFilters, setShowFilters] = useState(false);

  const { data } = useQuery<LibraryWorryDomains>(GET_ALL_WORRY_DOMAINS);
  const { data: allWorryDomainsAndTagsData } = useQuery<LibraryWorryDomainsAndTags>(GET_ALL_WORRY_DOMAINS_AND_TAGS);

  const worryDomainsSearchParam = getArrayFromSearchParam(worryDomainIds);
  const tagsSearchParam = getArrayFromSearchParam(tagIds);

  const queryWorryDomains =
    (worryDomainsSearchParam.length && worryDomainsSearchParam) ||
    (filters.worryDomain && [filters.worryDomain.pk.toString()]) ||
    [];
  const queryTags =
    (tagsSearchParam.length && tagsSearchParam) || (filters.tags && filters.tags.map((tag) => tag.pk.toString()));
  const queryAgeRanges = filters.ageRanges.map((ageRange) => ageRange.pk.toString());

  const { data: dataSearchResources } = useQuery<SearchResources, SearchResourcesVariables>(SEARCH_RESOURCES, {
    variables: { search, worryDomains: queryWorryDomains, tags: queryTags, ageRanges: queryAgeRanges },
  });

  const handleChangeValue = (value: string | number) => {
    // TODO: debounce
    if (typeof value === 'string') pushSearchParams({ search: value || undefined });
  };

  const handleWorryDomainClick = (id: number | null) => () =>
    pushSearchParams({ worryDomainIds: id ? [String(id)] : undefined });

  const worryDomains = data?.worryDomains?.edges ?? [];

  const getResources = () => {
    return dataSearchResources?.resources?.edges ?? [];
  };

  const toggleFilters = () => setShowFilters((isVisible) => !isVisible);

  const onSetFilters = (updatedFilters: FiltersList) => {
    setFilters(updatedFilters);
    setShowFilters(false);
  };

  const removeAgeRangeFilter = (pk: number) => () =>
    setFilters((currentFilters) => {
      const updatedAgeRanges = currentFilters.ageRanges.filter((item) => item.pk !== pk);
      return {
        ...currentFilters,
        ageRanges: updatedAgeRanges,
      };
    });

  const removeWorryDomainFilter = () =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      worryDomain: undefined,
    }));

  const removeTagFilter = (pk: number) => () =>
    setFilters((currentFilters) => {
      const updatedTags = currentFilters.tags.filter((item) => item.pk !== pk);
      return {
        ...currentFilters,
        tags: updatedTags,
      };
    });

  const headerText = getHeaderText(allWorryDomainsAndTagsData, worryDomainsSearchParam, tagsSearchParam);

  return (
    <MarginChildren>
      {headerText.length > 0 && <Header as="h4">{headerText}</Header>}
      <p>Learn more about Little Otter's focus areas and find the help you need.</p>
      {!worryDomainIds?.length && !tagIds?.length && (
        <SearchContainer>
          <StyledInput
            name="search"
            placeholder="Search for articles, videos, tips, etc"
            icon={<Icon name="Search" />}
            value={search ?? ''}
            onChange={handleChangeValue}
          />
          <StyledButton size="smallest" variant="tertiary" onClick={toggleFilters}>
            <Icon name="Filters" />
          </StyledButton>
        </SearchContainer>
      )}
      <Filters isVisible={showFilters} currentFilters={filters} onChange={onSetFilters} />
      {(filters.ageRanges.length > 0 || filters.tags.length > 0 || filters.worryDomain) && (
        <TagsContainer>
          {filters.ageRanges.map((ageTag) => (
            <RemovableElement key={ageTag.pk} onClick={removeAgeRangeFilter(ageTag.pk)}>
              <Tag backgroundColor="yellow">{ageTag.name}</Tag>
            </RemovableElement>
          ))}
          {filters.worryDomain && (
            <RemovableElement onClick={removeWorryDomainFilter}>
              <WorryDomainOrResourceTag worryDomainOrResource={filters.worryDomain} />
            </RemovableElement>
          )}
          {filters.tags.map((tag) => (
            <RemovableElement key={tag.pk} onClick={removeTagFilter(tag.pk)}>
              <StyledTag>{tag.name}</StyledTag>
            </RemovableElement>
          ))}
        </TagsContainer>
      )}
      {queryWorryDomains.length || queryTags.length || queryAgeRanges.length || search ? (
        <Resources resources={getResources()} />
      ) : (
        <WorryDomains worryDomains={worryDomains} onClick={handleWorryDomainClick} />
      )}
    </MarginChildren>
  );
};
