import { math } from 'polished';
import styled, { css } from 'styled-components';

import { Button, Link } from '@littleotter/zz-legacy-components';

export const StyledSection = styled.section`
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5`)};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.5`)};

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

const buttonStyles = css`
  background: ${({ theme }) => theme.deprecated_.colors.green};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.35`)};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  margin: 0 auto;
  max-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 21`)};
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4.5`)};
`;

export const StyledButton = styled(Button)`
  ${buttonStyles}
`;

export const StyledLink = styled(Link).attrs({ variant: 'primary' })`
  ${buttonStyles}
`;

export const StyledError = styled.p`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.red};
  margin-bottom: 10px;
  text-align: left;
`;

export const StyledStackTrace = styled.p`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text};
  margin-bottom: 25px;
  text-align: left;
`;

export const StyledErrorContainer = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 30px auto;
  padding: 60px;
  background-color: lightgray;
`;

export const StyledContextContainer = styled.div`
  margin-bottom: 3em;
  text-align: left;
  line-height: 1.3em;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.smaller};
  color: dimgray;
`;

export const NumberedList = styled.ol`
  margin: 1em;
  list-style-type: decimal;
  list-style-position: outside;
  li {
    margin: 0.6em;
  }
`;
