import { useSingletonEffect } from '@littleotter/zz-kit/react';

import { useEventTracking } from '$services/event-tracking';
import { useFeatureFlagIdentityManager } from '$services/featureFlags';
import { logoutProxyUrl } from '$shared/utils/proxy';

/**
 * The legacy logout page handles clearing any identity-based functionality from the legacy app, and then
 * redirects to the next-gen app to handle the actual logout process.
 */
export const LegacyLogout = () => {
  const { clearIdentity: clearEventIdentity } = useEventTracking();
  const { clearIdentity: clearFfIdentity } = useFeatureFlagIdentityManager();

  useSingletonEffect(() => {
    // Clear any saved user identities from integrated services
    clearEventIdentity();
    clearFfIdentity();

    // Redirect the user to the next-gen logout page
    window.location.href = logoutProxyUrl;
  });

  return null;
};
