import { consoleDebug } from '@littleotter/zz-kit/utils';

import { type IEnvironmentService } from '../environment/types';
import { PlatformLoggerService } from './PlatformLoggerService';
import type { ILoggerService, ILogHandler } from './types';

export const createClientLogService = (env: IEnvironmentService, handlers: ILogHandler[]): ILoggerService => {
  consoleDebug('[platform.logging] Creating logging service');

  return new PlatformLoggerService(env, handlers);
};
