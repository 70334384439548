import { format } from 'date-fns';
import { useMemo } from 'react';
import { type Column, type SortByFn, type TableOptions } from 'react-table';

import { Link } from '@littleotter/zz-legacy-components';

import { CellText } from '../styled';
import { type AppointmentData, type AppointmentsTableDataItem } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAppointmentData = (value: any): value is AppointmentData =>
  value?.startDatetime !== undefined && value?.appointmentId !== undefined;

const dateSortType: SortByFn<AppointmentsTableDataItem> = (rowA, rowB, columnId) => {
  const [valueA, valueB] = [rowA, rowB].map((row) => row.values[columnId]);

  if (!isAppointmentData(valueA) || !isAppointmentData(valueB)) {
    throw new Error('only row with `AppointmentData` is supported');
  }

  const [timeA, timeB] = [valueA, valueB].map((value) => value.startDatetime.getTime());

  if (timeA === timeB) {
    return 0;
  }

  return timeA > timeB ? 1 : -1;
};

export const useTableOptions = (tableData: AppointmentsTableDataItem[]): TableOptions<AppointmentsTableDataItem> => {
  const columns = useMemo(
    (): Column<AppointmentsTableDataItem>[] => [
      {
        id: 'date',
        Header: 'Date',
        accessor: (data) => data.appointment,
        Cell({ value }: { value: AppointmentData }) {
          return (
            <Link isAnchor href={`https://intakeq.com/#/booking/${value.appointmentId}`}>
              {format(value.startDatetime, 'M/d h:mm aaa')}
            </Link>
          );
        },
        sortType: dateSortType,
      },
      {
        id: 'type',
        Header: 'Type',
        accessor: (data) => data.type,
      },
      {
        id: 'providerName',
        Header: 'Provider',
        accessor: (data) => data.providerName,
      },
      {
        id: 'zoomLink',
        Header: 'Zoom Link',
        accessor: (data) => data.zoomLink,
        Cell({ value }: { value: string }) {
          if (!value) {
            return <CellText>No Zoom meeting attached</CellText>;
          }

          return (
            <Link isAnchor href={value}>
              Join Call
            </Link>
          );
        },
      },
    ],
    []
  );

  const sortBy = useMemo(() => [{ id: 'date', desc: true }], []);

  return {
    columns,
    data: tableData,
    initialState: {
      sortBy,
    },
  };
};
