import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { Link } from '@littleotter/zz-legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { AssessmentHeader } from '../../../components/AssessmentHeader';
import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type BackToSchoolReportQuery,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType,
} from '../../../graphql/__generated__/BackToSchoolReportQuery';
import { BACK_TO_SCHOOL_REPORT_QUERY } from '../../../graphql/back-to-school-report';
import { MissingQueryDataError } from '../../../graphql/errors';
import { BackToSchoolReportContent } from './BackToSchoolReportContent';

type PathParams = {
  id: string;
};

export const BackToSchoolReport: FC<PropsWithChildren> = () => {
  const params = useParams<PathParams>();

  const { data, loading, error } = useQuery<BackToSchoolReportQuery>(BACK_TO_SCHOOL_REPORT_QUERY, {
    variables: { uuid: params.id },
  });
  useGraphQLErrorHandling(error);

  if (loading) return <PageWideLoading />;
  if (!data?.assessmentsNamespace.assessmentResponse) throw new MissingQueryDataError('BackToSchoolReportQuery');

  return (
    <>
      <AssessmentHeader>
        <Link variant="clearStyles" isAnchor href="https://www.littleotterhealth.com/backtoschool">
          <img
            src="https://littleotter-assets.s3.amazonaws.com/assessments/back-to-school/logo.svg"
            alt="Back to School Logo"
          />
        </Link>
      </AssessmentHeader>
      <BackToSchoolReportContent
        reportResult={
          data.assessmentsNamespace.assessmentResponse
            .result as BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType
        }
        updatedAt={new Date(data.assessmentsNamespace.assessmentResponse.updatedAt)}
      />
    </>
  );
};
