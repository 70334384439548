import { type DetailedHTMLProps, type FC, type InputHTMLAttributes, type PropsWithChildren } from 'react';

import { Icon } from '@littleotter/zz-legacy-components';

import { HiddenInput, IconContainer, StyledIconButton } from './styled';

export const FileUploadIcon: FC<
  PropsWithChildren<Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'>>
> = ({ name, ...props }) => (
  <StyledIconButton as="label">
    <IconContainer>
      <Icon name="Attachment" />
    </IconContainer>
    <HiddenInput type="file" id={name} name={name} accept="image/*,video/*,audio/*,.pdf,.m4a" {...props} />
  </StyledIconButton>
);
