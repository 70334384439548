import { Capacitor } from '@capacitor/core';
import { type FC, type PropsWithChildren } from 'react';

import { Navbar } from '@littleotter/zz-legacy-components';

import { PageWrapper } from '../../styled';
import {
  isCaregiverSubject,
  isChildSubject,
  isFamilySubject,
  type ChildSubject,
  type QuestionData,
  type Subject,
} from '../../types';
import {
  StyledCardTitle,
  StyledContainer,
  StyledContent,
  StyledProgressBar,
  StyledProgressBarContainer,
  StyledProgressContainer,
  StyledQuestionStatus,
} from './styled';

export const CheckupFrame: FC<
  PropsWithChildren<{
    navbarDark?: boolean;
    navbarShowLogo?: boolean;
    goBack: () => void;
    question?: QuestionData;
    questionIndex?: number;
    totalQuestions?: number;
    subjectsQuestionsLength?: number;
    subjectIndex?: number;
    subject?: Subject;
  }>
> = ({
  children,
  navbarDark,
  navbarShowLogo,
  goBack,
  question,
  questionIndex = 0,
  totalQuestions = 0,
  subjectsQuestionsLength = 0,
  subjectIndex = 0,
  subject,
}) => {
  const isPrompt = question?.question?.promptOptions;
  const questionProgress = (questionIndex / totalQuestions) * 100;
  const subjectsQuestionsLengthTotal = subjectsQuestionsLength + 1;
  const progressIndex = subjectIndex + 1;

  const progressBarList = [...Array(subjectsQuestionsLengthTotal).keys()];

  const getProgress = (index: number) => {
    if (progressIndex > index) return 100;
    if (index === progressIndex) return questionProgress;
    return 0;
  };

  const getSubjectName = () => {
    if (!subject) return '';
    if (isCaregiverSubject(subject)) return 'You';
    if (isFamilySubject(subject)) return 'Family';
    if (isChildSubject(subject)) {
      const child = subject as ChildSubject;
      return child.firstName;
    }
    return '';
  };

  const subjectName = getSubjectName();

  const isOniOS = Capacitor.getPlatform() === 'ios';

  return (
    <>
      <Navbar hasExtraIosPadding={isOniOS} dark={navbarDark} showLogo={navbarShowLogo} goBack={goBack} />
      {progressBarList.length > 1 && (
        <StyledProgressContainer>
          <PageWrapper>
            <StyledProgressBarContainer>
              {progressBarList.map((value, index) => (
                <StyledProgressBar key={value} progress={getProgress(index)} />
              ))}
            </StyledProgressBarContainer>
            <StyledQuestionStatus>
              <span>
                {questionIndex} / {totalQuestions}
              </span>
            </StyledQuestionStatus>
          </PageWrapper>
        </StyledProgressContainer>
      )}
      <StyledContainer>
        <PageWrapper>
          {subjectName && !isPrompt && (
            <StyledCardTitle>
              <span>{subjectName}</span>
            </StyledCardTitle>
          )}
          <StyledContent roundTopBorders={!!isPrompt || !subjectName}>
            <div>{children}</div>
          </StyledContent>
        </PageWrapper>
      </StyledContainer>
    </>
  );
};
