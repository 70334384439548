import { math } from 'polished';
import styled from 'styled-components';

import { Icon } from '@littleotter/zz-legacy-components';

import { ReactComponent as ScheduleIllustration } from './schedule.svg';

export const Card = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.deprecated_.sizeBasis};
  border-radius: ${({ theme }) => theme.deprecated_.sizeBasis};
  box-shadow: 0px 26px 60px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubHeader = styled.p`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

export const CTA = styled.div<{ useYellow?: boolean; joinNow?: boolean }>`
  padding: ${(props) => props.theme.deprecated_.sizeBasis};
  background-color: ${({ theme, useYellow }) =>
    useYellow ? theme.deprecated_.colors.yellow : theme.deprecated_.colors.lightPurple};
  border-radius: ${({ theme }) => theme.deprecated_.sizeBasis};
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  display: flex;
  align-items: center;
  justify-content: ${({ joinNow }) => (joinNow ? 'flex-start' : 'space-around')};
`;

export const StyledIcon = styled(Icon)`
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  path {
    fill: ${({ theme }) => theme.deprecated_.colors.text};
  }
`;

export const StyledScheduleIllustration = styled(ScheduleIllustration)`
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4`)};
`;

export const SeeAllLink = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  text-align: right;
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;
