import { type FC, type PropsWithChildren } from 'react';

import { BrandCard, Header, Link } from '@littleotter/zz-legacy-components';

import { interpolatingLinearFunction } from '$shared/utils/interpolatingLinearFunction';

import { type PercentilePoints } from '../../../../components/AssessmentReportBarChart';
import {
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_emotionalChallenges,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_socialChallenges,
} from '../../../../graphql/__generated__/BackToSchoolReportQuery';
import { ChallengeRisks } from '../../../../graphql/__generated__/globalTypes';
import { routes } from '../../../../routes';
import { CollapseSection } from './CollapseSection';
import { CenteredAssessmentReportBarChart, Section } from './styled';

type ChildEmotionalAndSocialHealthProps = {
  emotionalChallengesResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_emotionalChallenges;
  socialChallengesResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_socialChallenges;
};

export const ChildEmotionalAndSocialHealth: FC<PropsWithChildren<ChildEmotionalAndSocialHealthProps>> = ({
  emotionalChallengesResult,
  socialChallengesResult,
}) => {
  const concernThreshold = 80;

  const fitEmotionalScoreToPercentiles = interpolatingLinearFunction(
    emotionalChallengesResult.scorePercentiles as PercentilePoints
  );
  const fitSocialScoreToPercentiles = interpolatingLinearFunction(
    socialChallengesResult.scorePercentiles as PercentilePoints
  );

  return (
    <Section>
      <Header as="h4" id="child-emotional-and-social-health">
        Child Emotional & Social Health
      </Header>
      <p>
        {emotionalChallengesResult.risk === ChallengeRisks.typical ? (
          <>
            <strong>Typical emotional challenges</strong> for your child’s age.
          </>
        ) : (
          <>
            <strong>Higher level of emotional challenges</strong> for your child’s age.
          </>
        )}
        <br />
        {socialChallengesResult.risk === ChallengeRisks.typical ? (
          <>
            <strong>Typical peer relationships</strong> for your child’s age.
          </>
        ) : (
          <>
            <strong>Higher level of peer relationship challenges</strong> for your child’s age.
          </>
        )}
      </p>

      <CenteredAssessmentReportBarChart
        data={[
          {
            id: 'Emotional Challenges',
            value: fitEmotionalScoreToPercentiles(emotionalChallengesResult.score),
          },
          {
            id: 'Social Challenges',
            value: fitSocialScoreToPercentiles(socialChallengesResult.score),
          },
        ]}
        concernThreshold={concernThreshold}
        yMax={100}
        yGridInterval={20}
      />

      <CollapseSection
        sectionTitle="Emotional Challenges"
        meaningContent={emotionalChallengesResult.content.meaning}
        whatICanDoToSupportContent={emotionalChallengesResult.content.whatICanDoToSupport}
        activityToDoContent={emotionalChallengesResult.content.activityToDo}
      />

      <CollapseSection
        sectionTitle="Social Challenges"
        meaningContent={socialChallengesResult.content.meaning}
        whatICanDoToSupportContent={socialChallengesResult.content.whatICanDoToSupport}
        activityToDoContent={socialChallengesResult.content.activityToDo}
      />

      <BrandCard>
        <strong>Looking for a bit of extra support?</strong>{' '}
        <Link isAnchor href={routes.unauthOnboarding.url()}>
          <strong>Join the Little Otter family</strong>
        </Link>{' '}
        and access expert clinicians and personalized care plan for your family
      </BrandCard>
    </Section>
  );
};
