import { motion } from 'framer-motion';
import { math } from 'polished';
import styled, { css } from 'styled-components';

import { linkStyles } from '@littleotter/zz-legacy-components';

import { addAlpha } from '$shared/utils/styles';

import { ReadStatus } from '../../../../components';

type StyledListItemProps = {
  isOutgoing: boolean;
};

export const StyledListItem = styled(motion.li)<StyledListItemProps>`
  display: flex;
  justify-content: ${({ isOutgoing }) => (isOutgoing ? 'flex-end' : 'flex-start')};
`;

const bubbleArrowWidth = '8px';

const opacity50Percent = (color: string, isDeleted?: boolean) => {
  return isDeleted ? `${color}80` : color;
};

export const ChatBubble = styled.div<{ isOutgoing: boolean; isDeleted?: boolean }>`
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  max-width: 75%;
  white-space: pre-wrap;
  position: relative;

  ${({ isDeleted, isOutgoing }) =>
    isDeleted &&
    !isOutgoing &&
    css`
      opacity: 0.5;
    `}

  ${({ isOutgoing, isDeleted, theme }) =>
    isOutgoing
      ? css`
          background-color: ${opacity50Percent(theme.deprecated_.colors.brandPurple, isDeleted)};
          color: ${theme.deprecated_.colors.black};
          margin-right: ${math(`${bubbleArrowWidth} * 2 + 4px`)};
        `
      : css`
          background-color: ${addAlpha(theme.deprecated_.colors.brightGray, 0.6)};
          margin-left: ${math(`${bubbleArrowWidth} * 2 + 4px`)};
        `}

  &:after {
    content: '';
    position: absolute;
    border: ${bubbleArrowWidth} solid transparent;
    width: 0;
    height: 0;
    top: 16px;
    ${({ isOutgoing, isDeleted, theme }) =>
      isOutgoing
        ? css`
            border-left-color: ${opacity50Percent(theme.deprecated_.colors.brandPurple, isDeleted)};
            right: ${math(`-${bubbleArrowWidth} * 2`)};
          `
        : css`
            left: ${math(`-${bubbleArrowWidth} * 2`)};
          `}
  }

  &:before {
    content: '';
    border: ${math(`${bubbleArrowWidth} + 2px`)} solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    top: 14px;
    ${({ isOutgoing, isDeleted, theme }) =>
      isOutgoing
        ? css`
            border-left-color: ${opacity50Percent(theme.deprecated_.colors.brandPurple, isDeleted)};
            right: ${math(`-${bubbleArrowWidth} * 2 - 4px`)};
          `
        : css`
            border-right-color: ${addAlpha(theme.deprecated_.colors.brightGray, 0.6)};
            left: ${math(`-${bubbleArrowWidth} * 2 - 4px`)};
          `}
  }
`;

export const ChatBubbleAttachment = styled.div<{ isDeleted?: boolean; isOutgoing?: boolean }>`
  ${({ isDeleted, isOutgoing }) =>
    isDeleted &&
    isOutgoing &&
    css`
      opacity: 0.5;
    `}

  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;

export const ChatBubbleTextContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis} / 2`)};

  & > * {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  }
`;

export const MessageMetaData = styled.div<{ isOutgoing: boolean }>`
  text-align: right;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: ${({ theme }) => addAlpha(theme.deprecated_.colors.darkGray, 0.8)};
  ${({ theme, isOutgoing }) => !isOutgoing && `color: ${addAlpha(theme.deprecated_.colors.darkGray, 0.8)};`}
`;

export const StyledLink = styled.a<{ isOutgoing: boolean }>`
  ${linkStyles};
  ${({ theme, isOutgoing }) =>
    isOutgoing &&
    `
    color: ${theme.deprecated_.colors.white} !important;
    font-weight: ${theme.deprecated_.fontWeights.bold};
    `}
`;

export const MessageBody = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  flex: 100%;
  word-break: break-word;
`;

const attachmentConstraints = css`
  max-width: 100%;
  max-height: calc(${({ theme }) => theme.deprecated_.sizeBasis} * 12);
`;

export const StyledImg = styled.img`
  ${attachmentConstraints}
`;

export const StyledVideo = styled.video`
  ${attachmentConstraints}
`;

export const StyledAudio = styled.audio`
  ${attachmentConstraints}
`;

export const StyledReadStatus = styled(ReadStatus)`
  line-height: ${({ theme }) => theme.deprecated_.fontSizes.smaller};
  path {
    fill: ${({ read, theme }) => (read ? theme.deprecated_.colors.textBlue : theme.deprecated_.colors.gray)};
  }
`;

export const StyledName = styled.p`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.smaller};
  color: ${({ theme }) => theme.deprecated_.colors.textBlue};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;
