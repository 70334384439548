import { type PropsWithChildren } from 'react';

import { MultiProfilePicture, ProfilePicture } from '@littleotter/zz-legacy-components';

import { DJANGO_BACKEND_SERVICE } from '$configs/env';

import { type ProfileViewerQuery_viewer } from '../../../graphql/__generated__/ProfileViewerQuery';
import { routes } from '../../../routes';
import { useViewer } from '../../../shared/contexts/Viewer';
import {
  CardImageContainer,
  SettingsButtonCard,
  SettingsContainer,
  SettingsFixedLinkCard,
  SettingsLinkCard,
  StyledForm,
} from '../components';
import { ReactComponent as Billing } from './Billing.svg';

type ProfileBasePageProps = {
  user: ProfileViewerQuery_viewer;
};

export const ProfileBasePage = ({ user }: PropsWithChildren<ProfileBasePageProps>) => {
  const caregiversProfilePictures = user.family?.caregivers.map((caregiver) => caregiver.profilePicture) ?? [];
  const providersProfilePictures = user.family?.children[0]?.providers.map((provider) => provider.profilePicture) ?? [];
  const { isInAllowedState } = useViewer();

  const logoutRoute = routes.legacyLogout.url();

  return (
    <SettingsContainer>
      <SettingsLinkCard
        href={routes.profile.family.url()}
        leftSection={
          caregiversProfilePictures.length > 1 ? (
            <MultiProfilePicture srcList={caregiversProfilePictures} sizeMultiplier={2.5} />
          ) : (
            <ProfilePicture size="smallest" src={caregiversProfilePictures[0]} />
          )
        }
        text="Family management"
      />
      <SettingsLinkCard
        href={routes.profile.me.url()}
        leftSection={<ProfilePicture size="smallest" src={user.profilePicture} />}
        text="Profile & security"
      />
      {isInAllowedState && (
        <SettingsLinkCard
          href={routes.profile.care.home.url()}
          leftSection={
            providersProfilePictures.length > 1 ? (
              <MultiProfilePicture srcList={providersProfilePictures} sizeMultiplier={2.5} />
            ) : (
              <ProfilePicture size="smallest" src={providersProfilePictures[0]} />
            )
          }
          text="Care Team"
        />
      )}
      <StyledForm action={`${DJANGO_BACKEND_SERVICE.ROOT_URL}/stripe/customer_portal_session`} method="POST">
        <SettingsButtonCard
          leftSection={
            <CardImageContainer>
              <Billing />
            </CardImageContainer>
          }
          text="Billing"
        />
      </StyledForm>
      <SettingsFixedLinkCard href={logoutRoute} leftSection={<></>} text="Log Out" />
    </SettingsContainer>
  );
};
