import styled from 'styled-components';

import { ControlledCollapse } from '@littleotter/zz-legacy-components';

export const DisabledCollapse = styled(ControlledCollapse)`
  opacity: 0.5;

  & > button {
    cursor: unset;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  margin-left: 1rem;
`;
