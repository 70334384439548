import { type FC, type PropsWithChildren } from 'react';

import { Header, ProfilePicture, Tag } from '@littleotter/zz-legacy-components';

import { capitalize } from '$shared/utils/formatString';

import { type ProviderClientQuery_user_family_children } from '../../../graphql/__generated__/ProviderClientQuery';
import { Card, ChildInfoContainer, ProfileDetails, WorryDomainsSection } from './components';

type ChildDetailsCardProps = {
  child: ProviderClientQuery_user_family_children;
};

export const ChildDetailsCard: FC<PropsWithChildren<ChildDetailsCardProps>> = ({
  child: { profilePicture, firstName, lastName, gender, worryDomains },
}) => (
  <Card>
    <ChildInfoContainer>
      <ProfilePicture size="small" src={profilePicture} />
      <ProfileDetails>
        <Header as="h3">
          {firstName} {lastName}
        </Header>
        {gender && <div>{capitalize(gender)}</div>}
      </ProfileDetails>
    </ChildInfoContainer>
    <WorryDomainsSection>
      <Header as="h4">Worry domains</Header>
      {worryDomains.edges.map((edge) => {
        const worryDomain = edge?.node;

        if (!worryDomain) return null;

        return (
          <div key={worryDomain.id}>
            <Tag>{worryDomain.name}</Tag>
          </div>
        );
      })}
    </WorryDomainsSection>
  </Card>
);
