import { type CartesianMarkerProps } from '@nivo/core';
import { ResponsiveLine, type LineSvgProps } from '@nivo/line';
import { compareAsc } from 'date-fns';
import { type FC, type PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { type Theme } from '@littleotter/zz-legacy-components';

import { type TimeSeries } from '../../types';
import { dateToString } from '../dateUtils';
import { MAX_X_AXIS, MIN_X_AXIS, THRESHOLD_X_VALUES } from '../threshold/constants';
import { getThresholdMarker } from '../threshold/marker';
import { thresholdLayerFactory } from '../threshold/ThresholdLayer';
import * as Styled from './styled';

const NUM_GRID_LINES = 8;

/**
 * DomainScoreOverTime renders a time series for a domain score with a threshold line and area.
 *
 * The graph has a fixed number of x-axis point and will render each point in the time series data equidistant
 * from each other, regardless of what the actual timestamps are.
 *
 * LIMITATIONS: this only works for a single time series. When we have multiple informants and multiple
 * time series we want to plot on the same chart, we will need to re-design and re-implement the x-axis
 * for this component.
 */
export const DomainScoreOverTime: FC<PropsWithChildren<DomainScoreOverTimeProps>> = ({
  timeSeries,
  threshold,
  maxScore,
}) => {
  const sortedTimeSeries = [...timeSeries].sort((a, b) => compareAsc(a.date, b.date));
  const {
    deprecated_: { colors, fontSizes, fontWeights },
  } = useTheme() as Theme;

  // configure axes and grid properties
  const indexToDateStr: { [key: number]: string } = sortedTimeSeries.reduce(
    (res, { date }, index) => {
      res[index + 1] = dateToString(date);
      return res;
    },
    {} as { [key: number]: string }
  );
  const dateIndexAxis = sortedTimeSeries.map((_, index) => index + 1);

  const yGridSpacing = maxScore > NUM_GRID_LINES ? Math.round(maxScore / (NUM_GRID_LINES - 1)) : 1;
  const yGridValues = Array.from({ length: Math.ceil(maxScore / yGridSpacing) + 1 }, (_, i) => yGridSpacing * i);
  const axesProperties: Partial<LineSvgProps> = {
    enableGridX: false,
    enableGridY: true,
    gridYValues: yGridValues,
    xScale: { type: 'linear', min: MIN_X_AXIS, max: MAX_X_AXIS },
    yScale: { type: 'linear', min: -0.5, max: maxScore },
    axisLeft: { tickValues: [] },
    axisBottom: {
      format: (idx: number) => indexToDateStr[idx],
      tickValues: dateIndexAxis,
      tickSize: 0,
      tickPadding: 5,
    },
  };

  const commonProperties: Partial<LineSvgProps> = {
    margin: { top: 20, right: 10, bottom: 30, left: 10 },
    theme: {
      background: colors.lightestGray,
    },
  };
  const lineProperties: Partial<LineSvgProps> = {
    colors: (d) => d.color,
    pointSize: 10,
    lineWidth: 2,
    pointBorderColor: { from: 'serieColor' },
  };

  // Define threshold layers - CustomLayer and Marker
  const markers: CartesianMarkerProps[] = [
    getThresholdMarker({
      label: 'concerning',
      maxScore,
      threshold,
      thresholdColor: colors.red,
      fontSize: fontSizes.smaller,
      fontWeight: fontWeights.bold,
    }),
  ];
  const thresholdLayer = thresholdLayerFactory({
    maxScore,
    threshold,
    thresholdXValues: THRESHOLD_X_VALUES,
    thresholdColor: colors.red,
  });

  const data = sortedTimeSeries.map(({ value }, index) => ({ x: index + 1, y: value }));
  return (
    <Styled.Root>
      <ResponsiveLine
        {...commonProperties}
        {...axesProperties}
        {...lineProperties}
        curve="monotoneX"
        data={[
          {
            id: 'line-data',
            data,
            color: colors.blueJeans,
          },
        ]}
        markers={markers}
        layers={['grid', thresholdLayer, 'lines', 'markers', 'axes', 'points', 'legends']}
      />
    </Styled.Root>
  );
};

export interface DomainScoreOverTimeProps {
  timeSeries: TimeSeries;
  threshold: number;
  maxScore: number;
}
