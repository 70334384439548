import { ClerkProvider as ActualClerkProvider, ClerkLoaded, ClerkLoading } from '@clerk/clerk-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { resolveRequiredEnvVar } from '@littleotter/zz-kit/env';

import { PageWideLoading } from '../../../components/PageWideLoading';

const CLERK_PUBLISHABLE_KEY = resolveRequiredEnvVar(
  'VITE_CLERK_PUBLISHABLE_KEY',
  process.env.VITE_CLERK_PUBLISHABLE_KEY
);

export const ClerkProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();

  useEffect(() => {
    if (!history?.push) {
      throw new Error('ClerkProvider must be a child of Router');
    }
  }, [history]);

  return (
    <ActualClerkProvider
      publishableKey={CLERK_PUBLISHABLE_KEY}
      routerReplace={(to) => history.replace(to)}
      routerPush={(to) => history.push(to)}
    >
      <ClerkLoading>
        <PageWideLoading />
      </ClerkLoading>
      <ClerkLoaded>{children}</ClerkLoaded>
    </ActualClerkProvider>
  );
};
