import styled from 'styled-components';

import { Link, ProfilePicture } from '@littleotter/zz-legacy-components';

export const StyledLinkListLink = styled(Link).attrs({ variant: 'clearStyles', $clickable: true })`
  align-items: center;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  cursor: pointer;
  display: flex;
  padding: ${({ theme }) => theme.spacing['03']} 0;
  overflow: hidden;
  transition: all 0.2s linear;
  width: 100%;
`;

export const CardLeftSection = styled.div`
  flex-shrink: 0;
`;

export const CardCenterSection = styled.div`
  flex-grow: 1;
`;

export const CardRightSection = styled.div`
  flex-shrink: 0;
`;

export const CardLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
`;
export const CardContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.deprecated_.colors.slateGray};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
`;

export const StyledCardRightSection = styled(CardRightSection)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledProfilePicture = styled(ProfilePicture)`
  border: ${({ theme }) => `3px solid ${theme.deprecated_.colors.white}`};
  box-sizing: content-box;
`;
