import { useQuery } from '@apollo/client';
import { endOfToday, startOfToday } from 'date-fns';
import { type FC, type PropsWithChildren } from 'react';

import { Header, Link } from '@littleotter/zz-legacy-components';

import { littleOtterSupport } from '$shared/urls';

import { ErrorBlock } from '../../../components/ErrorBlock';
import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type ProviderAppointmentsQuery,
  type ProviderAppointmentsQueryVariables,
} from '../../../graphql/__generated__/ProviderAppointmentsQuery';
import { PROVIDER_APPOINTMENTS_QUERY } from '../../../graphql/provider-home';
import { intakeQClientUrl } from '../../../routes';
import { ScheduleCard } from '../components';
import { CardsContainer, ScheduleSection } from './styled';

export const TodaysSchedule: FC<PropsWithChildren> = () => {
  const { data, loading, error } = useQuery<ProviderAppointmentsQuery, ProviderAppointmentsQueryVariables>(
    PROVIDER_APPOINTMENTS_QUERY,
    {
      variables: {
        startDatetime: startOfToday(),
        endDatetime: endOfToday(),
      },
    }
  );

  if (loading) return <PageWideLoading />;

  return (
    <ScheduleSection>
      <Header as="h2">Today's Schedule</Header>
      {!!data?.appointments.length && (
        <CardsContainer>
          {data.appointments.map((appointment) => (
            <ScheduleCard
              key={appointment.id}
              startDatetime={new Date(appointment.startDatetime)}
              endDatetime={new Date(appointment.endDatetime)}
              clientName={`${appointment.client.firstName} ${appointment.client.lastName}`}
              profileLink={intakeQClientUrl(appointment.client.externalClientUuid)}
              zoomLink={appointment.zoomLink}
            />
          ))}
        </CardsContainer>
      )}
      {data?.appointments.length === 0 && <p>No appointments today</p>}
      {error && (
        <>
          <p>There was an error fetching your appointments.</p>
          <p>
            Paste this error message in{' '}
            <Link href={littleOtterSupport} target="_blank" isAnchor>
              #little-otter-support
            </Link>{' '}
            for help:
          </p>
          <ErrorBlock>{error.message}</ErrorBlock>
        </>
      )}
    </ScheduleSection>
  );
};
