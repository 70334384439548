import { math } from 'polished';
import styled from 'styled-components';

import { DiscListStyle, ListStyles } from '@littleotter/zz-legacy-components';

export const StyledListItem = styled.li`
  ul {
    ${ListStyles}
    ${DiscListStyle}
    padding-inline-start: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

export const DomainScoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.75`)};

  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
`;

export const DomainCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.deprecated_.sizeBasis};
`;
