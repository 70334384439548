import { type FC, type PropsWithChildren } from 'react';

import { Loading } from '@littleotter/zz-legacy-components';

import { ErrorBoundary } from '$services/errors';

import { FamilyOverview } from './FamilyOverview/FamilyOverview';
import { useGetFmhcReport } from './scenarios/useGetFmhcReport';

export const SmartFamilyOverview: FC<PropsWithChildren<SmartFamilyOverviewProps>> = ({ fmhcId }) => {
  return (
    <ErrorBoundary fallback={() => <FamilyOverview people={[]} />}>
      <SmartFamilyOverviewControlled fmhcId={fmhcId} />
    </ErrorBoundary>
  );
};

const SmartFamilyOverviewControlled: FC<PropsWithChildren<SmartFamilyOverviewProps>> = ({ fmhcId }) => {
  const [people, loading] = useGetFmhcReport(fmhcId);
  return loading ? <Loading /> : <FamilyOverview people={people} />;
};

export interface SmartFamilyOverviewProps {
  fmhcId: string;
}
