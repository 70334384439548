import { type ComponentType, type FC, type MouseEventHandler, type PropsWithChildren } from 'react';

import { Loading } from '@littleotter/zz-legacy-components';

import { IconContainer, StyledIconButton } from './styled';

export const IconButton: FC<
  PropsWithChildren<{
    type: 'button' | 'submit' | 'reset';
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    isLoading?: boolean;
    Icon: ComponentType<PropsWithChildren>;
  }>
> = ({ type, onClick, disabled, isLoading, Icon }) => {
  return (
    <StyledIconButton type={type} onClick={onClick} disabled={disabled || isLoading}>
      <IconContainer>{isLoading ? <Loading width="24" /> : <Icon />}</IconContainer>
    </StyledIconButton>
  );
};
