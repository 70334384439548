import { useMutation } from '@apollo/client';
import { useState, type FC, type PropsWithChildren, type SyntheticEvent } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { BrandCard, Link, Loading } from '@littleotter/zz-legacy-components';

import { logger } from '$services/logging';
import { useViewer } from '$shared/contexts/Viewer';
import { useGraphQLErrorHandling } from '$shared/hooks';
import { supportProxyUrl } from '$shared/utils/proxy';

import {
  type CreateConversationMutation,
  type CreateConversationMutationVariables,
} from '../../../../../graphql/__generated__/CreateConversationMutation';
import { CREATE_CONVERSATION_MUTATION } from '../../../../../graphql/new-conversation-form';
import { routes } from '../../../../../routes';
import { IconButton } from '../NewMessageForm/IconButton';

enum STATE {
  INITIAL,
  CREATING,
  CREATED,
  ERROR,
}

type ConversationClosedBannerProps = {
  otherParticipants: number[];
  onCsvDownloadClick: () => void;
  isLoadingCsvButton: boolean;
};

const ConversationClosedBanner: FC<PropsWithChildren<ConversationClosedBannerProps>> = ({
  otherParticipants,
  onCsvDownloadClick,
  isLoadingCsvButton,
}) => {
  const history = useHistory();
  const [createConversation, { error }] = useMutation<CreateConversationMutation, CreateConversationMutationVariables>(
    CREATE_CONVERSATION_MUTATION
  );
  const { isStaffViewer } = useViewer();
  useGraphQLErrorHandling(error);
  const [state, setState] = useState<STATE>(STATE.INITIAL);

  const handleClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (state !== STATE.INITIAL) {
      return; // prevent multiple submissions
    }
    setState(STATE.CREATING);
    const { data } = await createConversation({
      variables: {
        participantsPks: otherParticipants,
      },
    });
    if (data?.createConversation.__typename === 'ConversationType') {
      setState(STATE.CREATED);
      history.push(
        routes.messages.conversation.url({
          id: data.createConversation.conversationSid,
        })
      );
      return;
    }
    setState(STATE.ERROR);
    const err = new Error('Failed to create conversation from archive');
    logger.error(err, {
      message: data?.createConversation.message,
    });
  };

  return (
    <BrandCard>
      <strong>You are viewing an archived conversation</strong>
      <br />
      {state === STATE.INITIAL && (
        <>
          This conversation has been archived due to inactivity.{' '}
          <Link onClick={handleClick}>Start a new conversation</Link>
        </>
      )}
      {[STATE.CREATING, STATE.CREATED].includes(state) && (
        <>
          Creating your new conversation <Loading />
        </>
      )}
      {state === STATE.ERROR && (
        <>
          Something went wrong. <Link href={supportProxyUrl}>Contact support</Link>
        </>
      )}
      {isStaffViewer && (
        <IconButton type="button" isLoading={isLoadingCsvButton} onClick={onCsvDownloadClick} Icon={FiDownload} />
      )}
    </BrandCard>
  );
};

export default ConversationClosedBanner;
