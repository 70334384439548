import { useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { stringifySearchParams } from '@littleotter/zz-kit/utils';
import { useTimeout } from '@littleotter/zz-legacy-components';

import { INTAKE_Q, isIntakeQProduction } from '$configs/env';
import { logger } from '$services/logging';

import { Container, StyledIframe } from './styled';
import { type WidgetBookedMessageData, type WidgetMessageData } from './types';

export type WidgetConfigType = {
  serviceId?: string;
  packageId?: string;
  practitionerId?: string;
  callback?: (appointmentData: WidgetBookedMessageData) => void;
  onLoaded?: () => void;
};

type IntakeQBookingWidgetType = {
  clientId: string;
  clientName: string;
  clientEmail: string;
  clientPhone: string | null;
  config: WidgetConfigType;
};

const LOADING_TIMEOUT_TIME = 40_000;

const IntakeQBookingWidget: FC<PropsWithChildren<IntakeQBookingWidgetType>> = ({
  clientId,
  clientName,
  clientEmail,
  clientPhone,
  config,
}) => {
  const isProd = isIntakeQProduction();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBooked, setIsBooked] = useState(false);

  const [height, setHeight] = useState(0);

  const { callback, onLoaded, packageId, practitionerId, serviceId } = config;

  useTimeout(() => {
    if (isLoaded) {
      return;
    }

    // If the "loaded" message was not received after LOADING_TIMEOUT_TIME, optimistically set
    // isLoaded to true and call the onLoaded callback.
    // If the widget loaded but didn't send the "loaded" message, we show the loaded widget to the user.
    // If the widget actually didn't finish loading, then the user would still be seeing a loader anyways.
    // In any case, we log the error to datadog so we're aware of the potential issue with IntakeQ or its widget.
    setIsLoaded(true);
    onLoaded?.();

    throw new Error(
      `IntakeQBookingWidget: Timeout error, "loaded" message not received after ${LOADING_TIMEOUT_TIME / 1000} seconds.`
    );
  }, LOADING_TIMEOUT_TIME);

  useEffect(() => {
    const onMsg = (event: MessageEvent) => {
      if (event.origin !== 'https://littleotter-assets.s3-us-west-2.amazonaws.com') return;

      const data: WidgetMessageData = JSON.parse(event.data);

      if (data.type === 'loaded') {
        setIsLoaded(true);
        onLoaded?.();
        logger.info(`IntakeQBookingWidget has loaded for userid: ${clientId}`);
        return;
      }

      if (data.type === 'booked') {
        setIsBooked(true);
        callback?.(data);
        logger.info(`IntakeQBookingWidget has booked appointment for userid: ${clientId}`);
      }

      if (data.type === 'resized') {
        setHeight(data.height);
      }
    };

    window.addEventListener('message', onMsg, false);

    return () => {
      window.removeEventListener('message', onMsg, false);
    };
  }, [callback, clientId, onLoaded]);

  return (
    <Container>
      <StyledIframe
        booked={isBooked}
        widgetHeight={height}
        scrolling="no"
        src={`https://littleotter-assets.s3-us-west-2.amazonaws.com/bookingwidget/intakeqwidget.html${stringifySearchParams(
          {
            clientName,
            clientEmail,
            clientPhone: clientPhone ?? undefined,
            serviceId,
            packageId,
            practitionerId,
            account: INTAKE_Q.ACCOUNT_ID,
            isTest: String(!isProd),
          }
        )}`}
        title="Little Otter Booking Widget"
      />
    </Container>
  );
};

export default IntakeQBookingWidget;
