import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Icon } from '@littleotter/zz-legacy-components';

import { CardCenterSection, CardLabel, StyledButtonCard } from '../styled';

const StyledNewCard = styled(StyledButtonCard)`
  display: flex;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.larger};
  background-color: transparent;

  & svg {
    font-size: 44px;
    margin-right: 16px;
  }
`;

const StyledLabel = styled(CardLabel)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export type AddNewCardProps = {
  onClick: () => void;
  labelText?: string;
};

export const AddNewCard: FC<PropsWithChildren<AddNewCardProps>> = ({ onClick, labelText }) => (
  <StyledNewCard onClick={onClick} variant="clearStyles">
    <Icon name="CircularPlus" />
    <CardCenterSection>
      <StyledLabel>{labelText}</StyledLabel>
    </CardCenterSection>
  </StyledNewCard>
);
