import { useQuery } from '@apollo/client';
import { math } from 'polished';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Header, Icon, Link } from '@littleotter/zz-legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { type CareSchedule } from '../../../graphql/__generated__/CareSchedule';
import { CARE_SCHEDULE } from '../../../graphql/care-schedule';
import { MissingQueryDataError } from '../../../graphql/errors';
import { routes } from '../../../routes';
import { StateLocationSourceEnum, type StateLocationSource } from '../../PaymentDetails/WelcomeCallPaymentDetails';

const CenteredMarginChildren = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  > * {
    max-width: 80%;
  }
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  }
`;

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

const StyledButton = styled(Button)`
  background-color: #365467;
  position: relative;
`;

const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.deprecated_.colors.text};
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  > svg {
    width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
    height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
    > path {
      fill: ${({ theme }) => theme.deprecated_.colors.white};
    }
  }
`;

const LabelContainer = styled.div`
  margin-left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
`;

export const CareTeamEmptyState = () => {
  // uses next-gen schedule appointments page
  const scheduleHref = `${process.env.NEXT_GEN_PROXY_URL}/care/appointments`;

  const { data, error, loading } = useQuery<CareSchedule>(CARE_SCHEDULE);
  useGraphQLErrorHandling(error);
  const history = useHistory();
  const viewer = data?.viewer;

  if (loading) return <PageWideLoading />;
  if (viewer?.upcomingAppointments === undefined || viewer?.hasCompletedAppointments === undefined)
    throw new MissingQueryDataError('CareSchedule');

  const { hasCompletedAppointments, upcomingAppointments } = viewer;

  const onSchedulePress = () => {
    const stateLocationSource: StateLocationSource = {
      source: StateLocationSourceEnum.CareTeam,
    };
    const route =
      hasCompletedAppointments || upcomingAppointments.length > 0
        ? scheduleHref
        : {
            pathname: routes.paymentDetails.welcomeCall.home.url(),
            state: stateLocationSource,
          };
    history.push(route);
  };

  return (
    <CenteredMarginChildren>
      <Header as="h4" useBodyFont>
        We can't wait to meet you!
      </Header>
      <StyledParagraph>
        Schedule your kickoff session to get connected to your Little Otter Care Team. Once you have your welcome
        appointment, you can message providers at anytime.
      </StyledParagraph>

      <StyledParagraph>
        Need help getting started?
        <br />
        Reach out to{' '}
        <Link href="mailto:help@littleotterhealth.com" isAnchor>
          help@littleotterhealth.com
        </Link>{' '}
        and a team member will respond as soon as possible.
      </StyledParagraph>
      <StyledButton variant="primary" onClick={onSchedulePress}>
        <IconContainer>
          <Icon name="Calendar" />
        </IconContainer>
        <LabelContainer>Schedule Appointment</LabelContainer>
      </StyledButton>
    </CenteredMarginChildren>
  );
};
