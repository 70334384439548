import { type FC, type PropsWithChildren } from 'react';

import { Checkbox } from '@littleotter/zz-legacy-components';

import { WorryDomainsContainer } from '../styled';
import { type WorryDomain } from '../types';

type WorryDomainsListProps = {
  worryDomains: WorryDomain[];
  selectedWorryDomainPks: number[];
  onChangeWorryDomain: (worryDomainPk: number, checked: boolean) => void;
};

export const WorryDomainsList: FC<PropsWithChildren<WorryDomainsListProps>> = ({
  worryDomains,
  selectedWorryDomainPks,
  onChangeWorryDomain,
}) => {
  return (
    <WorryDomainsContainer>
      {worryDomains.map((worryDomain) => (
        <Checkbox
          key={worryDomain.pk}
          displayAsTag
          tagStyleVariant="outline"
          name={worryDomain.shortName}
          label={worryDomain.name}
          checked={selectedWorryDomainPks.includes(worryDomain.pk)}
          onChange={(checked) => {
            onChangeWorryDomain(worryDomain.pk, checked);
          }}
        />
      ))}
    </WorryDomainsContainer>
  );
};
