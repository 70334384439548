import styled from 'styled-components';

import { Link } from '@littleotter/zz-legacy-components';

export const StyledZeroStateCardContainer = styled.div`
  background: ${({ theme }) => theme.deprecated_.colors.brightGray};
  border-radius: ${({ theme }) => theme.borderRadius['02']};
  padding: ${({ theme }) => theme.spacing['05']};
  margin: ${({ theme }) => theme.spacing['04']} 0 ${({ theme }) => theme.spacing['08']};
`;
export const StyledZeroStateCardDescription = styled.div`
  line-height: 1.5;
`;
export const StyledZeroStateCardLink = styled(Link)`
  display: block;
  padding-top: ${({ theme }) => theme.spacing['03']};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
