import { useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form6';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import { useTheme } from 'styled-components';

import { Input } from '@littleotter/zz-legacy-components';

// TODO(gveraperea): There should be an alias for these path so we avoid those path references.

import { logger } from '$services/logging';
import { useChildReferral } from '$shared/contexts/Child';
import { useBannerMessage } from '$shared/hooks';

import { BottomActionButtons } from '../../../../components/BottomActionButtons/BottomActionButtons';
import { WrongAuthorizationNumberModal } from '../WrongAuthorizationNumberModal';
import { AuthorizationTooltipContent, FieldContainer, IconAligner, LabelContainer, StyledForm } from './styled';

export type AuthorizationFormProps = {
  goNextPage: () => void;
  skipLinkAction?: () => void;
};

type FormAuthorizationInputs = {
  referralNumber: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  dateOfBirth: string | undefined;
};

const emptyChild = {
  referralNumber: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
};

export const AuthorizationForm: FC<PropsWithChildren<AuthorizationFormProps>> = ({
  goNextPage,
  skipLinkAction = () => undefined,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showWrongAuthModal, setShowWrongAuthModal] = useState<boolean>(false);
  const {
    isChildAuthorizationLoading,
    childAuthorizationData,
    onAddChildAuthorization,
    childInfo,
    childSelectedProvider,
  } = useChildReferral();

  const theme = useTheme();
  const { setBannerMessage } = useBannerMessage();

  const { errors, control, reset, setValue, formState, handleSubmit, clearErrors } = useForm({
    defaultValues: emptyChild,
    mode: 'all',
  });

  useEffect(() => {
    // Prepopulate fields from provider's data.
    if (!isChildAuthorizationLoading && childAuthorizationData) {
      const { dateOfBirth, referralNumber } = childAuthorizationData;
      setValue('referralNumber', referralNumber, { shouldValidate: true });
      setValue('dateOfBirth', dateOfBirth, { shouldValidate: true });
    } else {
      setValue('dateOfBirth', childInfo?.dateOfBirth, { shouldValidate: true });
    }
  }, [isChildAuthorizationLoading, childAuthorizationData, setValue, childInfo]);

  const onSubmit = async (formChildAuthorizationData: FormAuthorizationInputs) => {
    try {
      setLoading(true);
      clearErrors();
      const { referralNumber, dateOfBirth } = formChildAuthorizationData;

      const { isValid, errorMessages } = await onAddChildAuthorization({
        childId: childInfo?.id ?? '',
        insurancePartnerId: Number(childSelectedProvider?.id),
        referralNumber: referralNumber ?? '',
        dateOfBirth: dateOfBirth ?? '',
      });

      if (isValid) {
        reset(emptyChild);
        goNextPage();
        return;
      }
      if (errorMessages) {
        setShowWrongAuthModal(true);
      }
    } catch (e) {
      logger.error(new Error('Error submitting child insurance authorization', { cause: e }));
      setBannerMessage({ type: 'error', message: 'Something went wrong. Try again later.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <WrongAuthorizationNumberModal
        isOpen={showWrongAuthModal}
        onSkipClick={skipLinkAction || false}
        closeHandler={() => setShowWrongAuthModal(false)}
        codeLabel={childSelectedProvider?.codeLabel}
      />
      <Controller
        as={Input}
        label={
          <LabelContainer>
            Child's {childSelectedProvider?.codeLabel}
            <IconAligner data-tip data-for="authorizationTooltip" data-event="click focus">
              <BsFillQuestionCircleFill color={theme.deprecated_.colors.gray} />
            </IconAligner>
          </LabelContainer>
        }
        name="referralNumber"
        control={control}
        errors={errors}
        rules={{ required: `Child's ${childSelectedProvider?.codeLabel} is required` }}
      />
      <ReactTooltip
        id="authorizationTooltip"
        place="bottom"
        type="dark"
        effect="float"
        multiline
        backgroundColor="#EAECF6"
        textColor="#293E4C"
      >
        <AuthorizationTooltipContent>
          Your {childSelectedProvider?.codeLabel} should've been provided to you by {childSelectedProvider?.name} via
          their preferred communication channel. If you have {childSelectedProvider?.name} coverage but have not yet
          received a referral for Little Otter care, please reach out to {childSelectedProvider?.name} directly.
        </AuthorizationTooltipContent>
      </ReactTooltip>
      <FieldContainer hidden={!childSelectedProvider?.validateDateOfBirth}>
        <Controller
          as={Input}
          label="Child's date of birth"
          name="dateOfBirth"
          type="date"
          control={control}
          errors={errors}
          rules={{ required: "Child's date of birth is required" }}
        />
      </FieldContainer>
      <BottomActionButtons
        showLink
        submitText={`Add ${childSelectedProvider?.codeLabel}`}
        submitProps={{
          type: 'submit',
          variant: 'secondary',
          isLoading: loading,
          disabled: !formState.isValid,
        }}
        linkText="Continue without referral"
        linkAction={skipLinkAction}
      />
    </StyledForm>
  );
};
