import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/zz-legacy-components';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { MissingQueryDataError } from '../../../graphql/errors';
import { AppointmentsTable } from './components';
import { useAppointmentsTableData } from './hooks';
import { StyledSection } from './styled';

export const AppointmentsSection: FC<PropsWithChildren> = () => {
  const { tableData, data, loading } = useAppointmentsTableData();

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('ProviderFamilyAppointmentsQuery');

  return (
    <StyledSection>
      <Header as="h4">Appointments</Header>
      <AppointmentsTable tableData={tableData} />
    </StyledSection>
  );
};
