import { math } from 'polished';
import styled, { type DefaultTheme } from 'styled-components';

import { Tag } from '@littleotter/zz-legacy-components';

export const CardContainer = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  border: 1px solid ${({ theme }) => theme.deprecated_.colors.text}33;
  border-radius: 0.5rem;
  line-height: 1.3125;
  height: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.deprecated_.colors.brightGray}80;
  }
`;

export const CardTitle = styled.h2`
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.25`)};
  font-weight: 800;
`;

export const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const CardText = styled.p`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.875`)};
`;

export const CardScores = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.text}CC;
`;

export const StyledTag = styled(Tag)`
  border-radius: 3.5rem;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  padding: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 0.375`)} ${math(`${theme.deprecated_.sizeBasis} * 0.75`)} ${math(
      `${theme.deprecated_.sizeBasis} * 0.25`
    )} ${math(`${theme.deprecated_.sizeBasis} * 0.75`)}`};
`;

export const CardLabel = styled.span<{
  color: keyof DefaultTheme['deprecated_']['colors'];
}>`
  color: ${({ theme, color }) => theme.deprecated_.colors[color]};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;
