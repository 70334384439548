import { type CollapseItem } from '@littleotter/zz-legacy-components';

import { CollapseItemLabel, WorryDomainsList } from '../components';
import { type WorryDomain } from '../types';

type GetCollapseItemArgs = {
  id: string;
  label: string;
  selectedWorryDomainPks: number[];
  worryDomains: WorryDomain[];
  onChangeWorryDomain: (worryDomainPk: number, checked: boolean) => void;
};
type GetCollapseItem = (args: GetCollapseItemArgs) => CollapseItem;

export const getCollapseItem: GetCollapseItem = ({
  id,
  label,
  selectedWorryDomainPks,
  worryDomains,
  onChangeWorryDomain,
}) => ({
  id,
  label: <CollapseItemLabel label={label} selectedWorryDomainPks={selectedWorryDomainPks} />,
  children: (
    <WorryDomainsList
      worryDomains={worryDomains}
      selectedWorryDomainPks={selectedWorryDomainPks}
      onChangeWorryDomain={onChangeWorryDomain}
    />
  ),
});
