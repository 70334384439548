import { math } from 'polished';
import styled, { css } from 'styled-components';

import { Button, Link } from '@littleotter/zz-legacy-components';

export const SettingsContainer = styled.div`
  background: #fff;
`;
export const MarginChildren = styled.div``;

export const StyledForm = styled.form`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    width: auto;
  }

  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

export const StyledLabel = styled.label`
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  cursor: pointer;
  width: 100%;
  > div {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

export const AlignLeft = styled.div`
  align-self: flex-start;
`;

export const AlignCenter = styled.div`
  align-self: center;
  text-align: center;
`;

export const ChildProfileContainer = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;
export const ChildDetails = styled.div`
  margin-top: ${(props) => props.theme.deprecated_.sizeBasis};

  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

export const ProfileInputIconSpan = styled.span`
  vertical-align: middle;
`;

export const StyledProfilePictureInput = styled.label`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  cursor: pointer;
`;

export const HiddenInput = styled.input`
  display: none;
`;

const cardStyles = css<{ $clickable?: boolean; noHover?: boolean }>`
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5.5`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  ${({ $clickable }) => ($clickable ? 'cursor: pointer;' : '')}
  transition: all 0.2s linear;

  ${({ $clickable, noHover }) => ($clickable && !noHover ? '' : '')}
`;

export const StyledCard = styled.div`
  ${cardStyles}
`;

export const StyledLinkCard = styled(Link).attrs({ variant: 'clearStyles', $clickable: true })`
  ${cardStyles}
`;

export const StyledButtonCard = styled(Button).attrs({ $clickable: true })`
  ${cardStyles}
`;

export const StyledFixedLinkCard = styled(Link)`
  text-align: center;
  width: calc(100% - 32px);
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-color: inherit;
  color: inherit;

  // This bizarre calculations is legacy styles for the mobile nav height, we need to change this at some point
  bottom: max(
    calc(env(safe-area-inset-bottom) + ${({ theme }) => math(`5.5 * ${theme.deprecated_.sizeBasis}`)}),
    ${({ theme }) => math(`4.5 * ${theme.deprecated_.sizeBasis}`)}
  );

  @media (min-width: 720px) {
    position: relative;
    top: 50px;
  }
`;

export const CardImageContainer = styled.div`
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis}`)};
`;

export const CardLeftSection = styled.div`
  flex-shrink: 0;
`;

export const CardCenterSection = styled.div`
  flex-grow: 1;
`;

export const CardRightSection = styled.div`
  flex-shrink: 0;
`;

export const CardLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
`;

export const InsuranceInfoContainer = styled.div`
  text-alignt: left;
  width: 100%;
  margin-bottom: 0;

  & + button {
    margin-top: -16px;
  }
`;

export const InsuranceInfoTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: 16px;
`;

export const WorryDomainsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing['06']};

  & h4 {
    margin-bottom: ${({ theme }) => theme.spacing['04']};
  }
`;
