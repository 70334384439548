import { math, rgba } from 'polished';
import { IoVideocamOutline } from 'react-icons/io5';
import styled from 'styled-components';

import { Header } from '@littleotter/zz-legacy-components';

type HiddenSectionProps = {
  visible?: boolean;
};

export const HiddenSection = styled.section<HiddenSectionProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  margin-top: ${({ theme }) => theme.deprecated_.sizeBasis};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  letter-spacing: 0.03em;

  > :not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  }
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};

  > * {
    display: flex;
    align-items: center;
  }
`;

export const StyledVideoIcon = styled(IoVideocamOutline)`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis}`)};
`;

export const Footer = styled.footer`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => rgba(theme.deprecated_.colors.text, 0.8)};
  letter-spacing: 0.03em;
  padding-bottom: ${({ theme }) => theme.spacing['04']};

  > :not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  }
`;

export const StyledParagraph = styled.p`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  text-align: center;
`;

export const CardDetailsList = styled.ul`
  list-style: disc;
  padding-left: ${({ theme }) => theme.spacing['06']};
  margin: ${({ theme }) => theme.spacing['04']} 0;
`;

export const CardDetailsListItem = styled.li`
  letter-spacing: 0;
`;

export const StyledHeader = styled(Header)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  letter-spacing: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 1.4;
  margin-bottom: 10px;
`;

export const StyledVideoInfoWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.regularPlus};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const StyledPricingParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.regular};
`;
