import { type FC, type PropsWithChildren } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useSearchParams } from '@littleotter/zz-kit/react';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { type Route } from '../routes/types';
import { REDIRECT_KEY, RedirectError, type LocationState, type REDIRECT_PARAMS } from './types';

type Prerequisite = {
  predicate: boolean;
  route: Route;
  redirect?: Route;
  forward?: boolean;
  noRedirect?: boolean;
  state?: Record<string, unknown>;
};

type WithPrerequisitesProps = {
  prerequisites: Prerequisite[];
  loading: boolean;
};

/**
 * An FC which will redirect based on the prerequisites, or render its children.
 */
export const WithPrerequisites: FC<PropsWithChildren<WithPrerequisitesProps>> = ({
  prerequisites,
  loading,
  children,
}) => {
  const { state } = useLocation<LocationState>();
  const requiredPrerequisites = prerequisites.filter(({ predicate }) => predicate);
  return (
    <>
      {loading ? (
        <PageWideLoading />
      ) : (
        <>
          {/* Conditionally render children */}
          {requiredPrerequisites.length > 0 &&
            (requiredPrerequisites[0].noRedirect ? (
              <Redirect
                to={{
                  pathname: requiredPrerequisites[0].route.path,
                  state: { ...state, ...requiredPrerequisites[0].state },
                }}
              />
            ) : (
              // Only render the first required redirect
              <RedirectWithRedirect
                route={requiredPrerequisites[0].route}
                redirect={requiredPrerequisites[0].redirect}
                forward={requiredPrerequisites[0].forward}
                state={requiredPrerequisites[0].state}
              />
            ))}
          {requiredPrerequisites.length === 0 && <>{children}</>}
        </>
      )}
    </>
  );
};

type RedirectIfProps = {
  route: Route;
  redirect?: Route;
  forward?: boolean;
  state?: Record<string, unknown>;
};

/**
 * Returns a {@link Redirect} with a redirect search param back to the current location.
 *
 * If `redirect` is specified, a redirect param is added to the search params.
 * If `forward` is true, then the current redirect search param is forwarded.
 * Otherwise, the current location is passed as the redirect param.
 *
 * If both are specified, then a {@link RedirectError} is thrown.
 */
const RedirectWithRedirect: FC<PropsWithChildren<RedirectIfProps>> = ({
  route,
  redirect = undefined,
  forward = false,
  state = {},
}) => {
  if (redirect && forward) {
    throw new RedirectError('cannot redirect with redirect AND forward');
  }
  const { pathname, state: currentState } = useLocation<LocationState>();
  const [{ redirectTo }] = useSearchParams<REDIRECT_PARAMS>();
  const redirectValue = redirect?.url() ?? (forward ? redirectTo : pathname);

  return (
    <Redirect
      to={{
        pathname: route.path,
        search: `?${REDIRECT_KEY}=${redirectValue}`,
        state: { ...currentState, ...state },
      }}
    />
  );
};
