import { useQuery } from '@apollo/client';
import { math } from 'polished';
import styled from 'styled-components';

import { Header } from '@littleotter/zz-legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { MarginChildren } from '../../../components';
import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type LibraryForYou,
  type LibraryForYou_viewer_family_children_worryDomains_edges_node,
} from '../../../graphql/__generated__/LibraryForYou';
import { MissingQueryDataError } from '../../../graphql/errors';
import { LIBRARY_FOR_YOU_QUERY } from '../../../graphql/library-for-you';
import Resources from '../components/Resources';

const Container = styled(MarginChildren)`
  margin-bottom: ${({ theme }) => math(`5 * ${theme.deprecated_.sizeBasis}`)};
`;

const ForYou = () => {
  const { data, loading, error } = useQuery<LibraryForYou>(LIBRARY_FOR_YOU_QUERY);
  useGraphQLErrorHandling(error);

  if (loading) return <PageWideLoading />;
  if (!data?.viewer?.family) throw new MissingQueryDataError('LibraryForYou');

  const worryDomains =
    data.viewer.family.children.reduce<LibraryForYou_viewer_family_children_worryDomains_edges_node[]>(
      (worryDomainsAccumulator, child) => {
        const currentNodes = child.worryDomains.edges
          .map((edge) => edge?.node)
          .filter((node): node is LibraryForYou_viewer_family_children_worryDomains_edges_node => !!node);
        return [...worryDomainsAccumulator, ...currentNodes];
      },
      []
    ) || [];

  return (
    <Container>
      <p>
        Curated activities, and tools based on your focus areas, mental health check-up and children's ages. As you take
        more check-ups and work with your care team your library will increase.
      </p>
      {!!data.viewer.family?.manuallyCuratedResources?.edges.length && (
        <MarginChildren>
          <Header as="h4">From Your Provider</Header>
          <Resources horizontalScrolling resources={data.viewer.family.manuallyCuratedResources.edges} />
        </MarginChildren>
      )}
      <div>
        {worryDomains.map(
          (worryDomain) =>
            !!worryDomain?.resourcesByTag?.edges.length && (
              <MarginChildren key={worryDomain.id}>
                <Header as="h4">{worryDomain.name}</Header>
                <Resources horizontalScrolling resources={worryDomain.resourcesByTag.edges} />
              </MarginChildren>
            )
        )}
      </div>
    </Container>
  );
};

export default ForYou;
