import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Button } from '@littleotter/zz-legacy-components';

const ButtonLink = styled(Button).attrs({ $clickable: true })`
  color: rgb(39, 110, 190);
`;

type SettingsLinkProps = {
  onClick?: () => void;
  text: string;
  type: 'submit';
};

export const LinkButtom: FC<PropsWithChildren<SettingsLinkProps>> = ({ onClick, type, text }) => (
  <ButtonLink onClick={onClick} type={type} variant="clearStyles">
    {text}
  </ButtonLink>
);
