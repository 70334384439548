import { type FC, type PropsWithChildren } from 'react';

import { Collapse } from '@littleotter/zz-legacy-components';

import { IconWithBackground, type IconWithBackgroundName } from '../../../../../../../components/IconWithBackground';
import { Container, DisabledCollapse, Label } from './styled';

export type CollapseWithIconProps = {
  iconName: IconWithBackgroundName;
  label: string;
  defaultOpen?: boolean;
  disabled?: boolean;
  maxHeight?: string;
  onClick?: () => void;
};

export const CollapseWithIcon: FC<PropsWithChildren<CollapseWithIconProps>> = ({
  iconName,
  label,
  defaultOpen,
  children,
  disabled,
  maxHeight,
  onClick,
}) => {
  const labelElement = (
    <Container>
      <IconWithBackground name={iconName} variant="onboarding" />
      <Label>{label}</Label>
    </Container>
  );

  if (disabled) {
    return <DisabledCollapse label={labelElement} iconVariant="plus-minus" containerVariant="card" />;
  }

  return (
    <Collapse
      label={labelElement}
      iconVariant="plus-minus"
      containerVariant="card"
      defaultOpen={defaultOpen}
      maxHeight={maxHeight}
      onClick={onClick}
    >
      {children}
    </Collapse>
  );
};
