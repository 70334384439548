import styled from 'styled-components';

import { HTMLContainer } from '@littleotter/zz-legacy-components';

export const StyledContextContent = styled.div`
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const StyledTitle = styled.h3`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text};
`;

export const StyledContent = styled.p`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.regular};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text};
`;

export const StyledHtmlContainer = styled(HTMLContainer)`
  max-height: calc(1500px - 1rem);
  overflow-y: auto;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
