import { type FC, type PropsWithChildren } from 'react';

import { Collapse } from '@littleotter/zz-legacy-components';

import { useEventTracking } from '$services/event-tracking';

import { type UpcomingSession } from '../../../types';
import { StyledButton, StyledCalendarIcon, StyledContainer, StyledContent, StyledLabel, StyledTotal } from './styled';
import { UpcomingSessionList } from './UpcomingSessionList';

export type UpcomingSessionsProps = {
  hasBoughtReport: boolean;
  label: string;
  className?: string;
  hasCompletedAppointments: boolean;
  upcomingSessions: UpcomingSession[];
  onClick: () => void;
  hasValidInsuranceAuthorizations: boolean;
};

export const UpcomingSessions: FC<PropsWithChildren<UpcomingSessionsProps>> = ({
  hasBoughtReport,
  label,
  className,
  hasCompletedAppointments,
  upcomingSessions,
  onClick,
  hasValidInsuranceAuthorizations,
}) => {
  const { track } = useEventTracking();

  const totalUpcomingSessions: number = upcomingSessions?.length || 0;
  const labelElement = (
    <StyledContainer>
      <StyledCalendarIcon />
      <StyledLabel haveUpcomingSessions={!!totalUpcomingSessions}>
        <StyledTotal>{totalUpcomingSessions}</StyledTotal>
        {label}
      </StyledLabel>
    </StyledContainer>
  );

  const handleClick = () => {
    track('CLICKED_UPCOMING_SESSIONS_BUTTON');
    onClick();
  };

  const getContent = () => {
    if (totalUpcomingSessions > 0) {
      return <UpcomingSessionList upcomingSessions={upcomingSessions} />;
    }
    if (!hasCompletedAppointments && !hasBoughtReport) {
      return (
        <span>
          Our comprehensive, personalized approach is why 85% of children who have received care have seen improvements
          in just six weeks.
        </span>
      );
    }
    if (!hasCompletedAppointments && hasBoughtReport) {
      return (
        <span>
          Discuss your check-up results with a Little Otter Care Coordinator. Use the code <b>WELCOME20</b> for $20 off.
          Our team of experts will help you build a personalized care plan to meet your family needs.
        </span>
      );
    }
    return <span>Keep your care journey going with us! We can’t wait to support you.</span>;
  };

  const getButtonText = () => {
    if (totalUpcomingSessions > 0) {
      return 'View and edit full schedule';
    }
    if (!hasCompletedAppointments) {
      if (hasValidInsuranceAuthorizations) {
        return 'Book your free kickoff session';
      }
      return 'Book your kickoff session';
    }
    return 'Book your next session';
  };

  return (
    <Collapse
      defaultOpen={totalUpcomingSessions === 0}
      className={className}
      label={labelElement}
      iconVariant="plus-minus"
      containerVariant="card"
    >
      <StyledContent>{getContent()}</StyledContent>
      <StyledButton onClick={handleClick} variant="textBlue">
        {getButtonText()}
      </StyledButton>
    </Collapse>
  );
};
