import { useMutation, useQuery } from '@apollo/client';
import { useState, type ChangeEventHandler, type FC, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { Header, ProfilePicture, TagsContainer } from '@littleotter/zz-legacy-components';

import { logger } from '$services/logging';
import { useGraphQLErrorHandling, type BannerMessage } from '$shared/hooks';
import { useGoto } from '$shared/utils/rerouter';

import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type ProfileChildQuery,
  type ProfileChildQuery_child_worryDomains_edges_node,
} from '../../../graphql/__generated__/ProfileChildQuery';
import {
  type UpdateChildProfile,
  type UpdateChildProfile_updateChildProfile,
  type UpdateChildProfileVariables,
} from '../../../graphql/__generated__/UpdateChildProfile';
import { MissingMutationDataError, MissingQueryDataError } from '../../../graphql/errors';
import { PROFILE_CHILD_QUERY, UPDATE_CHILD_PROFILE_MUTATION } from '../../../graphql/profile';
import { routes } from '../../../routes';
import { Form } from '../../Child/Pages/ChildForm/Components/Form';
import { type ChildProfileFormData, type SaveChildErrors } from '../../Child/types';
import { WorryDomainOrResourceTag } from '../../Library/components/WorryDomainOrResourceTag';
import {
  AlignCenter,
  AlignLeft,
  ChildProfileContainer,
  InsuranceInfoContainer,
  InsuranceInfoTitle,
  ProfilePictureInput,
  WorryDomainsContainer,
} from '../components';
import { AddNewCard } from '../components/AddNewCard';
import { InsuranceAuthorizationCard } from '../components/InsuranceAuthorizationCard';
import { ReactComponent as SlothChild } from './SlothChild.svg';

type ChildProfileProps = {
  setBannerMessage: (message: BannerMessage | null) => void;
};

const formatGqlErrorMessages: (
  updateChildData: UpdateChildProfile_updateChildProfile
) => SaveChildErrors | undefined = (updateChildData) => {
  if (updateChildData?.__typename === 'UpdateChildProfileError') {
    const formatErrorArray = (errorArray: string[] | null | undefined) => (errorArray || []).join(', ');
    return {
      firstName: formatErrorArray(updateChildData.fieldErrors.firstName),
      lastName: formatErrorArray(updateChildData.fieldErrors.lastName),
      dateOfBirth: formatErrorArray(updateChildData.fieldErrors.dateOfBirth),
      gender: formatErrorArray(updateChildData.fieldErrors.gender),
      pronouns: formatErrorArray(updateChildData.fieldErrors.pronouns),
    };
  }
};

const formatPrepopulatedChildData = (childData: ProfileChildQuery | undefined) => {
  if (!childData) return;
  const { firstName, lastName, dateOfBirth, gender, pronouns } = childData.child;
  return {
    profilePicture: '',
    firstName,
    lastName: lastName ?? '',
    dateOfBirth,
    gender: gender ?? '',
    pronouns: pronouns ?? '',
    relationship: '',
  };
};

export const ChildProfile: FC<PropsWithChildren<ChildProfileProps>> = ({ setBannerMessage }) => {
  const [profilePicture, setProfilePicture] = useState<File | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<SaveChildErrors>();
  const { id } = useParams<{ id: string }>();
  const goInsuranceRoute = useGoto({
    route: routes.referral.selector,
    extraParams: [`redirectTo=${routes.profile.child.url({ id: Number(id) })}&childId=${id}`],
    referrerLocation: routes.profile.child.path,
  });
  const { data, loading, error } = useQuery<ProfileChildQuery>(PROFILE_CHILD_QUERY, {
    variables: { id: Number(id) },
    fetchPolicy: 'no-cache',
  });
  useGraphQLErrorHandling(error);
  const [updateProfile] = useMutation<UpdateChildProfile, UpdateChildProfileVariables>(UPDATE_CHILD_PROFILE_MUTATION);

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('ProfileChildQuery');

  const { child } = data;
  const { insuranceAuthorizations } = child;
  const onSubmit = async (submitData: ChildProfileFormData) => {
    try {
      const { firstName, lastName, dateOfBirth, gender, pronouns } = submitData;
      const result = await updateProfile({
        variables: {
          input: {
            id: Number(child.id),
            firstName,
            lastName,
            dateOfBirth,
            gender,
            pronouns,
            profilePicture,
          },
        },
      });
      const childResult = result.data?.updateChildProfile;
      if (!childResult) {
        throw new MissingMutationDataError('UpdateChildProfile');
      }

      switch (childResult.__typename) {
        case 'ChildType':
          setBannerMessage({ type: 'success', message: 'Your profile has been updated' });
          return;
        case 'UpdateChildProfileError':
          setBannerMessage({ type: 'error', message: childResult.message });
          setValidationErrors(formatGqlErrorMessages(childResult));
          return;
        case 'RateLimitError':
          setBannerMessage({ type: 'warning', message: childResult.message });
          return;
        default:
          throw new Error('Unknown result typename');
      }
    } catch (e) {
      logger.error(new Error('Error submitting ChildProfileFormData', { cause: e }));
      setBannerMessage({ type: 'error', message: 'Something went wrong. Try again later.' });
    }
  };

  const getFileFromInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    setProfilePicture(event.target.files?.[0]);
  };

  const worryDomains = child.worryDomains.edges
    .map((edge) => edge?.node)
    .filter((node): node is ProfileChildQuery_child_worryDomains_edges_node => !!node);

  return (
    <ChildProfileContainer>
      <AlignCenter>
        <ProfilePicture src={profilePicture || child.profilePicture} Placeholder={SlothChild} />
      </AlignCenter>
      <AlignCenter>
        <ProfilePictureInput name="profilePicture" onChange={getFileFromInput} />
      </AlignCenter>
      <AlignLeft>
        {worryDomains.length > 0 && (
          <WorryDomainsContainer>
            <Header as="h4">{child.displayName}'s Focus Areas</Header>
            <TagsContainer>
              {worryDomains.map((worryDomain) => (
                <WorryDomainOrResourceTag worryDomainOrResource={worryDomain} key={worryDomain.shortName} />
              ))}
            </TagsContainer>
          </WorryDomainsContainer>
        )}
        <Header as="h4">{child.displayName}'s Details</Header>
      </AlignLeft>
      <Form
        submitChildDataFn={onSubmit}
        prepopulatedChild={formatPrepopulatedChildData(data)}
        validationErrors={validationErrors}
      >
        {insuranceAuthorizations?.length > 0 && (
          <InsuranceInfoContainer>
            <InsuranceInfoTitle>Insurance Coverage</InsuranceInfoTitle>
            {insuranceAuthorizations.map((auth) => (
              <>
                <InsuranceAuthorizationCard
                  codeLabel={auth.insuranceReferral.insurancePartner.codeLabel}
                  authorizationCode={auth.insuranceReferral.referralNumber}
                  insurancePartner={auth.insuranceReferral.insurancePartner.name}
                  typeOfService={
                    auth.insuranceReferral.insurancePartner.coverageInfo || auth.insuranceReferral.typeOfService
                  }
                  expiration={auth.insuranceReferral.endDate}
                  key={auth.id}
                />
              </>
            ))}
          </InsuranceInfoContainer>
        )}
        <AddNewCard onClick={goInsuranceRoute} labelText="Add Insurance Information" />
      </Form>
    </ChildProfileContainer>
  );
};
