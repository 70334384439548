import { motion } from 'framer-motion';
import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Header, HTMLContainer, ProfilePicture } from '@littleotter/zz-legacy-components';

const Wrapper = styled(motion.article)`
  padding-top: 4.25rem;
`;

const Card = styled.div`
  padding: ${({ theme }) => math(`1.5 * ${theme.deprecated_.sizeBasis}`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  border-radius: 1rem;
`;

const ImageContainer = styled.div`
  position: relative;
  width: ${({ theme }) => math(`8.5 * ${theme.deprecated_.sizeBasis}`)};
  margin: -5.65rem auto 1.5rem;
`;

const CardContent = styled.div<{ limitDescriptionHeight?: boolean }>`
  text-align: center;

  ${({ limitDescriptionHeight }) =>
    limitDescriptionHeight
      ? css`
          max-height: 36vh;
          overflow-y: auto;
        `
      : ''}

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

const DescriptionParagraph = styled.div`
  text-align: left;
`;

export type ProviderBioCardProps = {
  fullName: string;
  title: string;
  description?: string;
  profilePictureSrc: string;
  limitDescriptionHeight?: boolean;
};

export const ProviderBioCard: FC<PropsWithChildren<ProviderBioCardProps>> = ({
  fullName,
  title,
  description,
  profilePictureSrc,
}) => {
  return (
    <Wrapper
      initial={{ opacity: 0, y: 70 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
    >
      <Card>
        <ImageContainer>
          <ProfilePicture customSizeMultiplier={8.5} src={profilePictureSrc} />
        </ImageContainer>
        <CardContent limitDescriptionHeight>
          <Header as="h4" useBodyFont>
            {fullName}
          </Header>
          <Header as="h5" style={{ color: '#909A9F' }}>
            {title}
          </Header>
          {description && (
            <DescriptionParagraph>
              <HTMLContainer content={description} />
            </DescriptionParagraph>
          )}
        </CardContent>
      </Card>
    </Wrapper>
  );
};
