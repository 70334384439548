import { Capacitor } from '@capacitor/core';
import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { Navbar } from '@littleotter/zz-legacy-components';

import { BackToTop } from '../../../components/BackToTop';
import { SubjectType, type DomainReport } from '../../../graphql/lo1/generated';
import { Card } from './components/Card/Card';
import { type Score } from './components/Card/types';
import { ReportsHeader } from './ReportHeader';
import {
  HashLink,
  ReportContainer,
  ReportsBackgroundContainer,
  ReportsSummaryCards,
  StyledBgLeftShape,
  StyledBgRightShape,
  StyledBgTopShape,
} from './styled';
import { SubmissionContent } from './SubmissionContent';
import { type FamilyData, type FmhcReport } from './types';

export type ReportsContentProps = {
  familyId: string;
  fmhcReports: FmhcReport[];
  familyData?: FamilyData;
  isSubmitterCaregiver?: boolean;
};

const isOniOS = Capacitor.getPlatform() === 'ios';

const getChild = (memberId?: string, familyData?: FamilyData) => {
  if (!memberId || !familyData) {
    return null;
  }

  return familyData.family.children.find((_child) => _child.id === memberId);
};

const getCaregiver = (memberId?: string, familyData?: FamilyData) => {
  if (!memberId || !familyData) {
    return null;
  }

  return familyData.family.caregivers.find((_caregiver) => _caregiver.id === memberId);
};

const getSubjectNameById = (memberId?: string, familyData?: FamilyData, subjectType?: SubjectType) => {
  if (!subjectType) {
    return '';
  }

  const result =
    subjectType === SubjectType.Child ? getChild(memberId, familyData) : getCaregiver(memberId, familyData);

  return result?.firstName || '';
};

export const ReportsContent: FC<PropsWithChildren<ReportsContentProps>> = ({
  familyId,
  fmhcReports,
  familyData,
  isSubmitterCaregiver,
}) => {
  const history = useHistory();

  const getFmhcReportMember = (fmhcReport: FmhcReport) => {
    const { subjectType } = fmhcReport.subject;

    const memberId =
      subjectType === SubjectType.Child
        ? fmhcReport.subject.childSubject?.childId
        : fmhcReport.submissionMetadata.submitterId;

    const name = subjectType !== SubjectType.Family ? getSubjectNameById(memberId, familyData, subjectType) : '';

    const isChild = subjectType === SubjectType.Child;
    const isCaregiver = subjectType === SubjectType.Caregiver;
    const isFamily = subjectType === SubjectType.Family;

    const child = getChild(memberId, familyData);

    const isSeekingCare = child?.isSeekingCare;
    const age = isChild ? fmhcReport.gqlFmhcReport.subject?.childSubject?.ageInMonths : undefined;

    const pronouns = child?.pronouns ?? undefined;
    const gender = child?.gender ?? undefined;
    const dateOfBirth = child?.dateOfBirth ?? undefined;

    return {
      name,
      isSeekingCare,
      isCaregiver,
      isFamily,
      age,
      pronouns,
      gender,
      dateOfBirth,
    };
  };

  const getFmhcReportScore = (domainReports: DomainReport[]): Score[] =>
    domainReports.map((domainReport) => ({
      ...domainReport.domainScore,
      label: domainReport.domainName,
    }));

  return (
    <div>
      <Navbar goBack={history.goBack} dark showLogo hasExtraIosPadding={isOniOS} />
      <ReportsBackgroundContainer>
        <StyledBgLeftShape />
        <StyledBgRightShape />
        <StyledBgTopShape />
        <ReportContainer>
          <ReportsHeader
            familyId={familyId}
            isCaregiver={isSubmitterCaregiver}
            submissionDate={new Date(fmhcReports[0].submissionMetadata.submissionTime)}
            submitterName={fmhcReports[0].submissionMetadata.submitterName}
          />

          <ReportsSummaryCards>
            {fmhcReports.map((fmhcReport) => (
              <HashLink key={fmhcReport.key} href={`#${fmhcReport.key}`}>
                <Card
                  member={getFmhcReportMember(fmhcReport)}
                  scores={getFmhcReportScore(fmhcReport.gqlFmhcReport.domainReports)}
                />
              </HashLink>
            ))}
          </ReportsSummaryCards>

          {fmhcReports.map((fmhcReport) => {
            return (
              <SubmissionContent
                id={fmhcReport.key}
                key={fmhcReport.key}
                fmhcReport={fmhcReport}
                member={getFmhcReportMember(fmhcReport)}
                familyData={familyData}
              />
            );
          })}
        </ReportContainer>
      </ReportsBackgroundContainer>
      <BackToTop />
    </div>
  );
};
