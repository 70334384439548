import { math } from 'polished';
import styled from 'styled-components';

import { Button } from '@littleotter/zz-legacy-components';

export const StyledContainer = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledContent = styled.div`
  margin-top: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => `clamp(${math(`${sizeBasis} * 1.875`)}, 16vh, ${math(`${sizeBasis} * 7.18`)})`};
`;

export const StyledText = styled.h1`
  font-size: ${({
    theme: {
      deprecated_: { fontSizes },
    },
  }) => math(`${fontSizes.regular} * 1.75`)};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-style: normal;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  color: ${({ theme }) => theme.deprecated_.colors.text};

  span {
    font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledButtonLink = styled.button`
  color: ${({ theme }) => theme.deprecated_.colors.text};
  margin: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 1.565`)} 0 ${math(`${theme.deprecated_.sizeBasis} * 0.375rem`)}`};
  display: inline-block;
  background-color: transparent;
  text-decoration: underline;
  border: none;
  cursor: pointer;

  &:hover,
  &:visited {
    color: ${({ theme }) => theme.deprecated_.colors.text};
  }
`;

export const StyledActionContainer = styled.div`
  text-align: center;
`;
